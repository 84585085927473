const mutations = {
	['UPDATE_CHAT_SESSION_ID'] (state, payload) {
		state.chatSessionId = payload.chatSessionId;
	},

	['UPDATE_CHAT_SESSION_MODEL'](state, payload) {
		state.chatSessionUseModel = payload.modelType;
	},

	['UPDATE_CUR_RECEIVE_SESSION_ID'](state, payload) {
		state.currentReceiveSessionId = payload.chatSessionId;
	},

	['UPDATE_UN_READ_MESSAGES'](state, payload) {
		state.unreadMessages = payload.messages;
	},

	['READ_MESSAGE'](state, payload) {
		const { app_id, path } = payload;

		for (let i = 0; i < state.unreadMessages.length; i++) {
			if (app_id === state.unreadMessages[i].app_id && path === state.unreadMessages[i].path) {
				state.unreadMessages.splice(i, 1);
				i--;
			}
		}
	}
};

export default mutations;