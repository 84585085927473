import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
	chatSessionId: '',
	currentReceiveSessionId: '',
	chatSessionUseModel: 0,
	unreadMessages: []
}

export default {
	state,
	getters,
	actions,
	mutations
};