import dayjs from 'dayjs';
import Decimal from 'decimal.js';
import { mapGetters } from 'vuex';
import { getInviteLink } from '@/api/login';
import { toFixedNumber, toFixed } from '@/utils/util';
import { readMessage } from '@/api/notify';
import { getAllCoupons, getAllSysCoupons, getCoupon } from '@/api/coupon';
import { gpt35, gpt40, dalle3, mj } from '@/config/model';
import { createOrder } from '@/api/order';
import { paySound, payFailSound } from '@/audio';
import { pointsIcon, shoppingCarIcon, creditRuleIcon, giftIcon, counponIcon, sendLoadingIcon, gouIcon } from '@/svg';
import Coupon from '@/components/Coupon/Coupon.vue';

const payList = [
  // 1折
  {
      rmb: 5,
      breanCount: '50万',
      free: '0万',
      id: '5',
  },

  // 9折
  {
      rmb: 10,
      breanCount: '100万',
      free: '10万',
      id: '10',
  },

  // 8.6折
  {
      rmb: 20,
      breanCount: '200万',
      free: '30万',
      id: '20',
  },

  // 8.3折
  {
      rmb: 50,
      breanCount: '500万',
      free: '100万',
      id: '50',
  },

  // 8折
  {
      rmb: 100,
      breanCount: '1000万',
      free: '250万',
      id: '100',
  },

  // 7.7折
  {
      rmb: 200,
      breanCount: '2000万',
      free: '600万',
      id: '200',
  },

  // 7.4折
  {
      rmb: 500,
      breanCount: '5000万',
      free: '1750万',
      id: '500',
  },

  // 7.1折
  {
      rmb: 1000,
      breanCount: '1亿',
      free: '4000万',
      id: '1000',
  },
];

if(localStorage.show_log === '1') {
  payList.unshift({
    rmb: 1,
    breanCount: '10万',
    free: '0',
    id: '1',
  });
}

export default {
	name: 'Credit',

  props: {
    openParams: {
      type: Object,
      default: () => {
        return {
          app: 'credit',
          path: 'buy'
        }
      }
    }
  },

	data() {
    const pagePath = this.openParams.path.split('?')[0];

		return {
			sendLoadingIcon,
			gouIcon,
			currentTab: pagePath,
      payMethod: 'wxpay',
			getInviteLinkLoading: false,
			creditLoading: false,
			selectedRMB: 5,
      gpt35,
      gpt40,
      dalle3,
      mj,
			selectedCouponId: null,
      payNoticeVisible: false,
			loadCounponLoading: false,
			payLoading: false,
			couponList: [],
      couponListLoading: false,
      sysCouponRenderList: [],
			payList,
			menuList: [
				{
					id: 'buy',
					name: '购买',
					icon: shoppingCarIcon,
          messageCount: 0
				},

        {
          id: 'coupon',
          name: '领券',
          icon: counponIcon,
          messageCount: 0
        },

				{
					id: 'invite',
					name: '邀请奖励',
					icon: giftIcon,
          messageCount: 0
				},
			]
		}
	},

	computed: {
		...mapGetters(['lemonBeanCount', 'setting', 'unReadMessages']),

		orderNum() {
			const strNumber = this.lemonBeanCount.toLocaleString();

			return strNumber.split('');
		},

		moneyResult() {
      const selectedCoupon = this.couponList.filter((coupon) => {
        return coupon.user_coupon_id === this.selectedCouponId;
      });

      if (!selectedCoupon[0]) {
        return toFixed(this.selectedRMB, 2);
      }

      const { discount } = selectedCoupon[0];
      const selectedRMBDecimal = new Decimal(this.selectedRMB);
      const discountDecimal = new Decimal(discount);
      const resultRMB = selectedRMBDecimal.times(discountDecimal);

      return toFixed(toFixedNumber(resultRMB.toString(), 2), 2);
    },

    subtractionRMB() {
      const selectedRMBDecimal = new Decimal(this.selectedRMB);
      const moneyResultDecimal = new Decimal(Number(this.moneyResult));
      const resultSubtractionRMB = selectedRMBDecimal.minus(moneyResultDecimal);

      return toFixed(toFixedNumber(resultSubtractionRMB.toString(), 2), 2);
    }
	},

	methods: {
    init() {
      const { path } = this.openParams;
      const pagePath = path.split('?')[0];
      const item = this.findItemByPath(pagePath);

      if (item) {
        this.chooseTab(item);
      }
      else {
        this.chooseTab(this.menuList[0]);
      }
    },

    findItemByPath(path) {
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].id === path) {
          return this.menuList[i];
        }
      }

      return null;
    },

		chooseTab(item) {
			this.currentTab = item.id;

			if (this.currentTab === 'buy') {
				this.couponList = [];
				this.refreshCreditCount();
				this.renderMyCoupon();
			}

      if (this.currentTab === 'coupon') {
        this.querySysCoupon();
      }

      if (item.messageCount > 0) {
        readMessage({
          app_id: 'credit',
          path: item.id
        });
        this.$store.dispatch('readMessages', {
          app_id: 'credit',
          path: item.id
        });
      }
		},

    async querySysCoupon() {
      this.couponListLoading = true;
      this.sysCouponRenderList = [];

      try {
        const res = await getAllSysCoupons();

        this.sysCouponRenderList = this.makeSysCouponRenderList(res);
      }
      catch(err) {}

      this.couponListLoading = false;
    },

    async getCoupon({coupon_id}) {
      this.updateCouponLoading(coupon_id, true);

      try {
        await getCoupon({
          couponId: coupon_id
        });

        this.sysCouponRenderList.forEach((coupon) => {
          if (coupon.coupon_id === coupon_id) {
            coupon.coupon_tag = 5;
          }
        });

        this.$message({
          message: '领取成功',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.setting.sound === '1' && paySound.play();
      }
      catch(err) {
        this.$message({
          message: err.code ? err.message : '领取失败',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        this.setting.sound === '1' && payFailSound.play();
      }

      this.updateCouponLoading(coupon_id, false);
    },

    updateCouponLoading(id, value) {
      this.sysCouponRenderList.forEach((coupon) => {
        if (coupon.coupon_id === id) {
          coupon.loading = value;
        }
      });
    },

    makeSysCouponRenderList(list) {
      return list.map((item) => {
        return {
          ...item,
          loading: false
        };
      });
    },

    pay() {
      if (this.payLoading) {
        return;
      }
      
      this.payNoticeVisible = true;
    },

		async payContinue() {
      this.$message({
        message: '请联系客服充值',
        type: 'error',
        showClose: true,
        customClass: 'custom-message',
      });
      return;
      
      this.payNoticeVisible = false;

      if (this.payLoading) {
        return;
      }

      this.payLoading = true;

      const params = {
        payType: this.payMethod,
        amount: this.selectedRMB,
        selectedCouponId: this.selectedCouponId
      };

      // this.$emit('onCreateOrder', {
      //   order_number: 'N8989489894898948',
      //   qrcode: 'https://static.next-ai.top/wise-sayings/img/wx_code.7c26313f.jpg',
      //   pay: 10
      // });
      // this.payLoading = false;
      // return;

      try {
        let res = await createOrder(params);

        if (res.code == 0){
          // this.$message({
          //   message: '支付跳转中，请稍后...',
          //   type: 'success',
          //   showClose: true,
          //   customClass: 'custom-message',
          // });
          // this.setting.sound === '1' && paySound.play();
          // setTimeout(() => {
          //   window.location = res.data.link;
          // }, 500);
          this.$emit('onCreateOrder', {
            order_number: res.data.order_number,
            qrcode: res.data.qrcode,
            pay: toFixed(res.data.pay, 2)
          });
        } else if (res.code === 802) {
          // 优惠券错误
          this.$message({
            message: res.message,
            type: 'error',
            showClose: true,
            customClass: 'custom-message',
          });
        } else {
          this.$message({
            message: '订单创建失败: ' + res.message,
            type: 'error',
            showClose: true,
            customClass: 'custom-message',
          });
        }
      }
      catch(err) {
        this.$message({
          message: '订单创建失败',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.payLoading = false;
	  },

		fixDiscount(discount) {
    	return toFixedNumber(discount * 10, 2);
    },

    transTime(time) {
      return dayjs(time).locale('zh-cn').format('YYYY.MM.DD');
    },

    selectCoupon(item) {
    	if (this.selectedCouponId === item.user_coupon_id) {
    		this.selectedCouponId = null;
    		return;
    	}

    	this.selectedCouponId = item.user_coupon_id;
    },

    async renderMyCoupon() {
    	this.loadCounponLoading = true;
      try {
        const list = await getAllCoupons();

        if (list.length) {
          this.selectedCouponId = list[0].user_coupon_id;
        }

        this.couponList = list;
      }
      catch(err) {
        console.log(err);
      }
      this.loadCounponLoading = false;
    },

		chooseAmount(item) {
			this.selectedRMB = item.rmb;
		},

		async refreshCreditCount() {
			if (this.creditLoading) {
				return;
			}
			
			this.creditLoading = true;
			await this.$store.dispatch('updateLemonBean');
			this.creditLoading = false;
		},

		async getInviteLink() {
			this.getInviteLinkLoading = true;

			const link = await getInviteLink();

			if (!link) {
				this.$message({
          message: '获取链接失败，请重试',
          showClose: true,
          type: 'error',
          customClass: 'custom-message',
        });
        this.getInviteLinkLoading = false;
        return;
			}

			this.getInviteLinkLoading = false;
			this.copy(link);
		},

    resetMessageCount() {
      this.menuList.forEach((menu) => {
        menu.messageCount = 0;
      });
    },

    updateMessageCount() {
      this.resetMessageCount();
      this.unReadMessages.forEach((unReadMessage) => {
        const { app_id, path } = unReadMessage;
        const pagePath = path.split('?')[0];

        if (app_id !== 'credit') {
          return;
        }

        this.addAppMessageCount(pagePath);
      });
    },

    addAppMessageCount(path) {
      this.menuList.forEach((menu) => {
        if (menu.id === path) {
          menu.messageCount++;
        }
      });
    },

		copy(text) {
      const textarea = document.createElement('textarea');
      
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();
      this.$message({
        message: '复制成功',
        showClose: true,
        type: 'success',
        customClass: 'custom-message',
      });
		},
	},

  mounted() {
    this.updateMessageCount();
  },

  watch: {
    unReadMessages: {
      handler() {
        this.updateMessageCount();
      }
    },

    openParams: {
      deep: true,
      handler(n) {
        const pagePath = n.path.split('?')[0];

        this.currentTab = pagePath;
      }
    }
  },

  components: {
    Coupon
  }
}