import { sleep } from '@/utils/util';
import { getUserInfo } from '@/api/userInfo';
import { loginOut } from '@/api/login';

const actions = {
	async updateUserInfo({ commit, state, rootState }, payload) {
		try {
			const res = await getUserInfo();

			if (res.code === 0) {
				commit('UPDATE_USER_INFO', {
					isLogin: true,
					userName: res.data.userName,
					userType: res.data.userType,
					lemonBeanCount: res.data.lemon_bean_count,
					openId: res.data.open_id || '',
					nickname: res.data.nickname || '',
					photo: res.data.photo || '',
					setting: res.data.setting,
					vipLevel: res.data.vip_level,
					vipRulesInfo: res.data.vip_rules_info,
					vipExpireDate: res.data.vip_expire_date
				});
				return;
			}

			commit('UPDATE_USER_INFO', {
				isLogin: false,
				userName: '',
				userType: '',
				openId: '',
				nickname: '',
				photo: '',
				setting: {},
				vipLevel: 0,
				vipRulesInfo: null,
				vipExpireDate: null,
			});
		}
		catch(err) {
			commit('UPDATE_USER_INFO', {
				isLogin: false,
				userName: '',
				userType: '',
				nickname: '',
				photo: '',
				openId: '',
				setting: {},
				vipLevel: 0,
				vipRulesInfo: null,
				vipExpireDate: null,
			});
		}
	},

	async updateLemonBean({ commit, state, rootState }, payload) {
		try {
			const res = await getUserInfo();

			if (res.code === 0) {
				commit('UPDATE_LEMON_BEAN_COUNT', {
					lemonBeanCount: res.data.lemon_bean_count,
				});
			}
		}
		catch(err) {
			
		}
	},

	updateSetting({ commit, state, rootState }, payload) {
		commit('UPDATE_SETTING', {
			...payload
		});
	},

	updatePlan({ commit, state, rootState }, payload) {
		commit('UPDATE_PLAN', {
			...payload,
		});
	},

	async loginOut({ commit, state, rootState }) {
		await loginOut();
		commit('UPDATE_USER_INFO', {
			isLogin: false,
			userName: '',
			userType: '',
			openId: '',
			nickname: '',
			photo: '',
			setting: {},
			vipLevel: 0,
			vipRulesInfo: null,
			vipExpireDate: null,
		});
	},

	async clearLoginStatus({ commit, state, rootState }) {
		commit('UPDATE_USER_INFO', {
			isLogin: false,
			userName: '',
			userType: '',
			nickname: '',
			photo: '',
			openId: '',
			setting: {},
			vipLevel: 0,
			vipRulesInfo: null,
			vipExpireDate: null,
		});
	}
}

export default actions;
