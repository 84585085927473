import Vue from 'vue';
// import * as fundebug from "fundebug-javascript";
// import FundebugVue from "fundebug-vue";
import App from './App.vue';
import ElementUI, { Loading } from 'element-ui';
import hevueImgPreview from 'hevue-img-preview';
import router from './router';
import store from './store';
import { sleep } from '@/utils/util';
import 'element-ui/lib/theme-chalk/index.css';

// fundebug.init({
//   apikey: '1b7bafcc364f3f4cded45bd2f2814d4440a0821269c475e596d8c089140d05d4'
// })
// new FundebugVue(fundebug).installVueErrorHandler(Vue);

Vue.use(ElementUI);
Vue.use(hevueImgPreview, {
  clickMaskCLose: true,
  keyboard: true
});
Vue.config.productionTip = false;

async function getUserInfo() {
  const loading = Loading.service({
    fullscreen: true,
    customClass: 'chat-loading',
    background: '#2f2f2f'
  });

  await store.dispatch('updateUserInfo');
  loading.close();
}

Promise.all([
  getUserInfo()
]).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
});
