import { chatGPT, mj, openai, ai } from '@/config/model';

export default {
	name: 'About',

	data() {
		return {
			chatGPT,
			openai,
			ai
		};
	}
};