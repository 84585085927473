import { mapGetters } from 'vuex';
import { chatGPTPhoto, editIcon } from '@/svg';

export default {
	name: 'new-chat',

	data() {
		return {
			chatGPTPhoto,
			editIcon
		}
	},

	computed: {
		...mapGetters(['chatSessionUseModel']),
	},

	methods: {
		openNewChat() {
			this.$emit('openNewChat');
		}
	}
};