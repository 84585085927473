import { mapGetters } from 'vuex';
import { useConvertCode } from '@/api/convert_code';
import { paySound, payFailSound } from '@/audio';
import { readMessage } from '@/api/notify';

export default {
	name: 'Convert',

	props: {
    openParams: {
      type: Object,
      default: () => {
        return {
          app: 'convert',
          path: ''
        }
      }
    }
  },

	data() {
		return {
			convertLoading: false,
			isFocus: false,
			code: ''
		}
	},

	computed: {
		...mapGetters(['setting', 'unReadMessages']),
	},

	methods: {
		init() {
			this.unReadMessages.forEach((unReadMessage) => {
				const { app_id } = unReadMessage;

				if (app_id !== 'convert') {
					return;
				}

				readMessage({
					app_id,
					path: ''
				});
				this.$store.dispatch('readMessages', {
	    		app_id,
	    		path: ''
	    	});
			});
		},

		async convert() {
			if (this.convertLoading) {
				return;
			}

			if (!this.code) {
				this.$message({
          message: '兑换码不能为空',
          showClose: true,
          type: 'error',
          duration: 1000,
          customClass: 'custom-message',
        });
        this.setting.sound === '1' && payFailSound.play();
        return;
			}

			this.convertLoading = true;

			try {
				const res = await useConvertCode({
					code: this.code
				});

				this.$message({
          message: res.message,
          showClose: true,
          type: 'success',
          customClass: 'custom-message',
        });
        this.$emit('onSuccess');
        this.setting.sound === '1' && paySound.play();
			}
			catch(err) {
				this.$message({
          message: err.message,
          showClose: true,
          type: 'error',
          customClass: 'custom-message',
        });
        this.setting.sound === '1' && payFailSound.play();
			}

			this.convertLoading = false;
		}
	}
}