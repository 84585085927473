import { Howl } from 'howler';

export const sendSound = new Howl({
  src: ['https://static.next-ai.top/chat-static-source/send_message.wav']
});

export const tapSound = new Howl({
  src: ['https://static.next-ai.top/chat-static-source/tap.mp3'],
  loop: true
});

export const switchSound = new Howl({
  src: ['https://static.next-ai.top/chat-static-source/switch.mp3']
});

export const receiveSound = new Howl({
  src: ['https://static.next-ai.top/chat-static-source/receive_message.wav']
});

export const successSound = new Howl({
  src: ['https://static.next-ai.top/chat-static-source/success.mp3'],
});

export const paySound = new Howl({
  src: ['https://static.next-ai.top/chat-static-source/pay.mp3']
});

export const payFailSound = new Howl({
  src: ['https://static.next-ai.top/chat-static-source/pay_fail.mp3']
});

export const delSound = new Howl({
  src: ['https://static.next-ai.top/chat-static-source/del.mp3']
});

export const notifySound = new Howl({
  src: ['https://static.next-ai.top/chat-static-source/notify.mp3']
});