import { chatGPTPhoto, userPhoto } from './svg.js';
import { fanchuanIcon, wenXinIcon } from '@/svg/index';
import defaultUserPhoto from '@/assets/user.jpg';

export default {
	props: {
		width: {
			type: Number,
			default: 10,
		},

		photo: {
			type: String,
			default: ''
		},

		vipLevel: {
			type: Number,
			default: 0
		},

		height: {
			type: Number,
			default: 10,
		},

		userType: {
			type: Number,
			default: 2
		},
	},

	data() {
		return {
			chatGPTPhoto,
			fanchuanIcon,
			wenXinIcon,
			defaultUserPhoto: userPhoto
		}
	},

	computed: {
		userPhoto() {
			return this.defaultUserPhoto;
		},
	}
};