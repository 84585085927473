export const actionButtons = [
	{
		alias: 'upscalepic1',
		value: 'U1',
		label: '放大 (图1)',
	},
	{
		alias: 'upscalepic2',
		value: 'U2',
		label: '放大 (图2)',
	},
	{
		alias: 'upscalepic3',
		value: 'U3',
		label: '放大 (图3)',
	},
	{
		alias: 'upscalepic4',
		value: 'U4',
		label: '放大 (图4)',
	},

	{
		alias: 'transform1',
		value: 'V1',
		label: '深化 (图1)',
	},
	{
		alias: 'transform2',
		value: 'V2',
		label: '深化 (图2)',
	},
	{
		alias: 'transform3',
		value: 'V3',
		label: '深化 (图3)',
	},
	{
		alias: 'transform4',
		value: 'V4',
		label: '深化 (图4)',
	},

	{
		alias: 'rePaint',
		value: 'reroll',
		label: '重绘',
	},

	{
		alias: 'transformSubtle',
		value: 'Vary (Subtle)',
		label: '细微调整'
	},
	{
		alias: 'transformStrong',
		value: 'Vary (Strong)',
		label: '深度调整'
	},
	{
		alias: 'zoomOut1.5x',
		value: 'Zoom Out 1.5x',
		label: '外景延伸1.5x'
	},
	{
		alias: 'zoomOut2x',
		value: 'Zoom Out 2x',
		label: '外景延伸2x'
	},
];

export function findActionInfoByValue(value) {
	const { label } = value;

	for (let i = 0; i < actionButtons.length; i++) {
		if (actionButtons[i].value === label) {
			return actionButtons[i];
		}
	}

	return null;
}