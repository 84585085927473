import _ from 'lodash';
import { mapGetters } from 'vuex';
import { vipRuleIcon, sendLoadingIcon } from '@/svg';
import { updateVIP } from '@/api/vip';
import { paySound, payFailSound } from '@/audio';
import { gpt35, gpt40, mj, dalle3 } from '@/config/model';
import { v0Plan } from './plan/v0';
import { v1Plan } from './plan/v1';
import { v2Plan } from './plan/v2';
import { v3Plan } from './plan/v3';
import { readMessage } from '@/api/notify';

export default {
	name: 'VIP',

	props: {
    openParams: {
      type: Object,
      default: () => {
        return {
          app: 'vip',
          path: ''
        }
      }
    }
  },

	data() {
		return {
			vipRuleIcon,
			sendLoadingIcon,
			gpt35,
			gpt40,
			mj,
			dalle3,
			loading: true,
			updatePlanLoading: false,
			showTips: true,
			plans: [
				{
					loading: false,
					...v0Plan
				},
				{
					loading: false,
					...v1Plan
				},
				{
					loading: false,
					...v2Plan
				},
				// {
				// 	loading: false,
				// 	...v3Plan
				// }
			]
		};
	},

	computed: {
		...mapGetters(['vipLevel', 'setting', 'unReadMessages']),
	},

	methods: {
		async init() {
			this.unReadMessages.forEach((unReadMessage) => {
				const { app_id } = unReadMessage;

				if (app_id !== 'vip') {
					return;
				}

				readMessage({
					app_id,
					path: ''
				});
				this.$store.dispatch('readMessages', {
	    		app_id,
	    		path: ''
	    	});
			});

			this.loading = true;
			await this.$store.dispatch('updateUserInfo');
			this.loading = false;
		},

		isNull(value) {
			return _.isNull(value);
		},

		buttonDisable(plan) {
			const { type } = plan;

			return type <= this.vipLevel;
		},

		buttonName(plan) {
			const { type } = plan;

			if (type !== this.vipLevel) {
				return '升级方案';
			}

			return '当前方案';
		},

		planName(type) {
			const planNames = {
				0: '普通会员',
				1: '铂金会员',
				2: '钻石会员',
				3: '白金会员'
			};

			return planNames[type];
		},

		async updateVIP(plan) {
			const { type } = plan;

			this.$message({
        message: `网站目前已停止运营，详情咨询客服微信:MercyTYY`,
        type: 'error',
        showClose: true,
        customClass: 'custom-message',
      });
      return;

			plan.loading = true;

			try {
				const { expire_date } = await updateVIP({
					vipType: type
				});
				this.$store.dispatch('updatePlan', {type, vipExpireDate: expire_date});
				this.$message({
          message: '升级成功',
          showClose: true,
          type: 'success',
          customClass: 'custom-message',
        });
				this.setting.sound === '1' && paySound.play();
			}
			catch(err) {
				this.$message({
          message: err.code ? err.message : '升级失败',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        if (this.setting.sound === '1') {
        	payFailSound.play();
        }
			}

			plan.loading = false;
		}
	}
};