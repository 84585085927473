import { network } from './network';

export async function getFavorite() {
	const res = await network({
		url: '/api/get_favorite',
		method: 'get',
		params: {}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

// 更新会话标题
export async function updateFavoriteName(params) {
	const { favorite_id, name } = params;
	const res = await network({
		url: '/api/update_favorite_name',
		method: 'post',
		params: {
			favorite_id,
			name
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function delFavorite(params) {
	const { favorite_id } = params;
	const res = await network({
		url: '/api/del_favorite',
		method: 'post',
		params: {
			favorite_id
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function getFavoriteContent(params) {
	const { favorite_id } = params;
	const res = await network({
		url: '/api/get_favorite_content',
		method: 'post',
		params: {
			favorite_id
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}
