import dayjs from 'dayjs';
import { Loading } from 'element-ui';
import { sendLoadingIcon, userPhoto } from '@/svg';
import { useConvertCode } from '@/api/convert_code';
import { prizeHistory } from '@/api/convert_code';
import { redPacketStatus } from '@/api/red_packet';

export default {
	name: 'Prize',

	data() {
		return {
			sendLoadingIcon,
			userPhoto,
			statusLoading: true,
			getLoading: false,
			status: {
				geted: false,
				is_over: false,
				result: '',
				totalCount: 0,
				last_time: 0,
				created_at: 0,
				canget_count: 0,
				history_list: [],
				not_exsit: false
			},
			showDetail: false,
			list: []
		};
	},

	computed: {
		canget() {
			return !this.status.is_over && !this.status.geted;
		},

		statusText() {
			if (this.status.is_over) {
				const { last_time, created_at } = this.status;
				const disTime = parseInt((last_time - created_at) / 1000);
				let text = '';

				if (disTime <= 60) {
					text = `${disTime}秒被抢光`;
				}
				else if (disTime > 60 && disTime <= 60 * 60) {
					text = `${parseInt(disTime / 60)}分钟被抢光`;
				}
				else {
					text = `${parseInt(disTime / (60 * 60))}小时被抢光`;
				}

				return `${this.status.totalCount}个红包，${text}`;
			}

			return `已领取${this.status.totalCount - this.status.canget_count}/${this.status.totalCount}个`;
		},

		blessText() {
			if (this.status.not_exsit) {
				return '红包不存在';
			}
			
			if (this.canget) {
				return '恭喜发财，大吉大利';
			}

			if (!this.status.geted && this.status.is_over) {
				return '手慢了，红包派完了';
			}

			return '';
		}
	},

	methods: {
		async start() {
			const loading = Loading.service({
		    fullscreen: true,
		    customClass: 'red-packet-loading',
		    background: '#171717'
		  });

		  await this.refreshStatus();
			
		  this.statusLoading = false;
			loading.close();
		},

		toTalk() {
			window.location.href = 'https://chat.next-ai.top/desktop';
		},

		async refreshStatus() {
			try {
		  	const redPacketStatusRes = await redPacketStatus({
					code: this.$route.params.convertCode
				});
		  	const { geted, is_over, result, total, canget_count, history_list, last_time, created_at } = redPacketStatusRes.data;

		  	this.status.geted = geted;
		  	this.status.is_over = is_over;
		  	this.status.result = result;
		  	this.status.totalCount = total;
		  	this.status.canget_count = canget_count;
		  	this.status.history_list = history_list;
		  	this.status.last_time = last_time;
		  	this.status.created_at = created_at;
		  	this.list = this.makeRenderList(history_list);

		  	if (geted) {
		  		this.showDetail = true;
		  	}
		  }
		  catch(err) {
		  	this.status.not_exsit = true;
		  }
		},

		// 领红包
		async open() {
			if (this.getLoading) {
		  	return;
		  }
		  
			const loading = Loading.service({
				target: this.$el.querySelector('.red-packet__open'),
		    fullscreen: true,
		    customClass: 'red-packet-get-loading',
		    background: 'rgba(0, 0, 0, 0)'
		  });

		  this.getLoading = true;

		  try {
				const res = await useConvertCode({
					code: this.$route.params.convertCode
				});

				await this.refreshStatus();
			}
			catch(err) {
				if (err.code === 10) {
					this.$message({
	          message: err.message,
	          showClose: true,
	          type: 'error',
	          customClass: 'custom-message',
	        });
				}
				await this.refreshStatus();
			}

			loading.close();
			this.getLoading = false;
		},

		transTime(time) {
      return dayjs(time).locale('zh-cn').format('HH:mm');
    },

		makeRenderList(list) {
			return list.map((item) => {
				const { nickname, photo } = item.user_detail;

				return {
					id: item.prize_history_id,
					nickname,
					time: item.created_at,
					headPhoto: photo,
					info: item.info
				};
			});
		}
	},

	mounted() {
		this.start();
	},
};