import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { marked }  from 'marked';
import { debounce } from 'lodash';
import { escapeHtmlStr, uuid, closest, getByClass, sleep } from '@/utils/util';
import UserPhoto from '@/components/UserPhoto/UserPhoto.vue';
import { sendLoadingIcon, moreIcon, renameIcon, delIcon } from '@/svg';
import { getFavorite, updateFavoriteName, delFavorite, getFavoriteContent } from '@/api/favorite';
import { delSound, payFailSound } from '@/audio';
import Message from '@/components/Message/Message.vue';

const errorMsg = '对不起，发生了一些错误，请重新提问。';

export default {
	name: 'Favorite',

	props: {
    openParams: {
      type: Object,
      default: () => {
        return {
          app: 'favorite',
          path: ''
        }
      }
    }
  },

	data() {
		return {
			sendLoadingIcon,
			moreIcon,
			renameIcon,
			delIcon,
			keyword: '',
			loadingList: true,
			loadingMessageList: true,
			delDialogVisible: false,
			delLoading: false,
			searchFocus: false,
			willDelFavoriteName: '',
			list: [],
			currentId: '',
			srcList: [],
			messageRenderList: []
		};
	},

	directives: {
		focus: {
	    inserted: function (el) {
	      el.focus();
	    }
		}
	},

	computed: {
		...mapGetters(['setting'])
	},

	methods: {
		reset() {
			this.messageRenderList = [];
			this.srcList = [];
			this.currentId = '';
			this.list = [];
			this.willDelFavoriteName = '';
		},

		async init() {
			this.list = [];
			this.loadingList = true;

			try {
				const res = await getFavorite();

				if (res.code === 0) {
					this.srcList = res.data.list;
					this.list = this.makeRenderList(res.data.list);

					if (this.srcList.length) {
						this.currentId = this.srcList[0].favorite_id;
						this.loadMessageList(this.currentId);
					}
					else {
						this.loadingMessageList = false;
						this.messageRenderList = [];
					}
				}
			}
			catch(err) {
				this.$message({
	        message: '获取收藏列表失败',
	        type: 'error',
	        showClose: true,
	        customClass: 'custom-message',
	      });
			}
			this.loadingList = false;
		},

		getCurrentFavorite() {
			for (let i = 0; i < this.srcList.length; i++) {
				if (this.srcList[i].favorite_id === this.currentId) {
					return this.srcList[i];
				}
			}

			return null;
		},

		scrollToStart() {
    	const scrollView = this.$el.querySelector('.favorite-page__scroll-view');

    	setTimeout(() => {
    		scrollView && scrollView.scrollTo(0, 0);
    	}, 0);
    },

		async loadMessageList(favorite_id) {
			this.loadingMessageList = true;
			try {
				const res = await getFavoriteContent({
					favorite_id
				});
				const { type } = this.getCurrentFavorite();

				this.messageRenderList = this.makeHistoryRenderList(res.data.list, type);
			}
			catch(err) {}
			this.loadingMessageList = false;
			this.$nextTick(() => {
				this.scrollToStart();
			});
		},

		makeHistoryRenderList(historyList, model_type) {
      const result = [];

      historyList.forEach((item) => {
        if (item.type === 'question') {
          result.push({
          	id: item.message_id + '',
					  role: 'user',
					  contentType: item.content_type,
					  type: item.type,
					  status: 'ready',
					  createdAt: item.created_at,
					  replyMessage: this.getReplyMessageContent(item.reply_message),
					  sourceContent: item.content,
					  content: item.content_type === 0 ? escapeHtmlStr(item.content || '') : item.content,
					  tokenCount: item.token_count,
					  errorMsg: item.content ? '' : errorMsg,
          });
        }

        if (item.type === 'answer') {
          result.push({
					  id: item.message_id + '',
					  role: 'bot',
					  type: 'question',
					  contentType: item.content_type,
					  modelType: model_type,
					  status: 'ready',
					  createdAt: item.created_at,
					  replyMessage: this.getReplyMessageContent(item.reply_message),
					  sourceContent: item.content_type === 0 ? item.content : '',
					  content: this.makeAnswerContent(item.content, item.content_type),
					  tokenCount: item.token_count,
					  errorMsg: item.content ? '' : errorMsg,
          });
        }
      });

      return result;
    },

    makeAnswerContent(srcContent, type) {
    	if (type === 0) {
    		return marked(srcContent || '');
    	}

    	if (type === 1) {
    		return JSON.parse(srcContent);
    	}

    	if (type === 2) {
    		const srcData = JSON.parse(srcContent);
    		let result = {
    			...srcData,
    			actionButtons: [],
    		};

    		return result;
    	}

    	return '未知消息类型';
    },

    getReplyMessageContent(info) {
      if (!info) {
        return null;
      }

      const { content, message_id } = info;
      const div = document.createElement('div');

      div.innerHTML = marked(content);

      return {
        content: div.textContent,
        id: message_id
      };
    },

		showEditInput(item) {
			item.edit = true;
			item.showOperatePanel = false;
		},

		nameEditHanlder(item) {
			item.edit = false;
			this.updateTitleName(item);
		},

		updateBack(item) {
			const { name } = this.findChatSrcData(item.id);

			item.title = name;
		},

		updateSrcData(item) {
			const oldData = this.findChatSrcData(item.id);

			oldData.name = item.title;
		},

		async updateTitleName(item) {
			const { id, title } = item;
			const oldData = this.findChatSrcData(item.id);

			// 标题未发生变化
			if (oldData.name === title) {
				return;
			}

			if (!title) {
				this.$message({
          message: '标题不能为空',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
				this.updateBack(item);
				return;
			}

			try {
				await updateFavoriteName({
					favorite_id: id,
					name: title
				});
				this.updateSrcData(item);
				this.$message({
          message: '重命名成功',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
			}
			catch(err) {
				this.$message({
          message: err.message,
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        this.updateBack(item);
			}
		},

		findChatSrcData(id) {
			for (let i = 0; i < this.srcList.length; i++) {
				if (this.srcList[i].favorite_id === id) {
					return this.srcList[i];
				}
			}

			return null;
		},

		delFavorite(item) {
			this.delDialogVisible = true;
			this.willDelFavoriteName = item.title;
			this.delItem = item;
			item.showOperatePanel = false;
		},

		checkFavorite(favorite_id) {
			this.currentId = favorite_id;
			this.loadMessageList(favorite_id);
		},

		async delFavoriteOk() {
			this.delLoading = true;

			try {
				await delFavorite({
					favorite_id: this.delItem.id
				});

				this.$message({
          message: '删除成功',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });

				// 如果删除的正好是当前选中的，需要自动切换到临近会话
        if (this.delItem.id === this.currentId) {
        	this.switchToNext();
        }

        this.deleteFromRenderList(this.delItem.id);

        if (this.setting.sound === '1') {
        	delSound.play();
        }
			}
			catch(err) {
				this.$message({
          message: '删除失败',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });

        if (this.setting.sound === '1') {
        	payFailSound.play();
        }
			}
			this.delLoading = false;
			this.delDialogVisible = false;
		},

		deleteFromRenderList(favorite_id) {
			for (let i = 0; i < this.list.length; i++) {
				if (this.list[i].id === favorite_id) {
					this.list.splice(i, 1);
					this.srcList.splice(i, 1);
					break;
				}
			}
		},

		searchHandler(keyword) {
			this.searchFavoriteSync(keyword);
		},

		searchFavorite(keyword) {
			const searchData = this.searchByKeyword(keyword);

			this.list = this.makeRenderList(searchData);
		},

		searchByKeyword(keyword) {
			const result = [];
			const re = new RegExp(`${keyword}`, 'i');

			if (!keyword) {
				return this.srcList.map((item) => {
					return {
						...item
					};
				});
			}

			this.srcList.forEach((item) => {
				if (re.test(item.name)) {
					result.push({
						...item
					});
				}
			});

			return result;
		},

		switchToNext() {
			const theNext = this.findNextId(this.delItem.id);

			if (theNext) {
				this.checkFavorite(theNext.favorite_id);
			}
			else {
				this.reset();
			}
		},

		findNextId(id) {
			if (this.srcList.length <= 1) {
				return '';
			}

			for(let i = 0; i < this.srcList.length; i++) {
				if (this.srcList[i].favorite_id !== id) {
					continue;
				}

				if (i === this.srcList.length - 1) {
					return this.srcList[this.srcList.length - 2];
				}

				return this.srcList[i + 1];
			}

			return null;
		},

		makeRenderList(srcList) {
			return srcList.map((item) => {
				return {
					title: item.name,
					subTitle: item.description + '...',
					id: item.favorite_id,
					type: item.type,
					showOperatePanel: false,
					edit: false,
					date: dayjs(item.created_at).locale('zh-cn').format('YYYY-MM-DD HH:mm')
				};
			});
		}
	},

	mounted() {
		this.searchFavoriteSync = debounce(this.searchFavorite, 300);
	},

	components: {
		UserPhoto,
		Message
	}
};
