import { mapGetters } from 'vuex';
import { getOrderStatus } from '@/api/order';
import { toDuble, sleep } from '@/utils/util';
import { timeoutIcon } from '@/svg';
import { paySound } from '@/audio';

export default {
	name: 'WXPay',

	props: {
		data: {
			type: Object,
			default: () => {}
		}
	},

	data() {
		return {
			timeoutIcon,
			time: 5 * 60 * 1000,
			status: 0,
		};
	},

	computed: {
		...mapGetters(['setting']),

		timeText() {
			const min = Math.floor(this.time / 60000);
			const sec = this.time % 60000 / 1000;

			return `${toDuble(min)}分${toDuble(sec)}秒`;
		}
	},

	methods: {
		init() {
			this.timer = setInterval(() => {
				this.time -= 1000;

				if (this.time <= 0) {
					this.status = 1;
					this.timerStop();
					return;
				}
			}, 1000);
			this.checkStatus();
		},

		async checkStatus() {
			// 过期后就不检查了
			if (this.status === 1) {
				return;
			}

			try {
				const res = await getOrderStatus({
					order_number: this.data.orderNumber
				});

				this.status = 2;
				this.$message({
          message: '支付成功',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
        this.setting.sound === '1' && paySound.play();
				this.timerStop();
			}
			catch(err) {
				if (err.code === 10) {
					await sleep(1000);
					this.checkStatus();
				}
			}
		},

		openBillList() {
			this.$emit('onOpenBillList');
		},

		timerStop() {
			if (!this.timer) {
				return;
			}

			clearInterval(this.timer);
			this.timer = null;
		}
	}
}

