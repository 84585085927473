import { getBillInfo } from "@/api/bill";
import { gpt35, gpt40, dalle3 } from '@/config/model';
import { Loading } from 'element-ui';
const dayjs = require('dayjs')

export default {
    data() {
        return {
            billList: [],
            gpt40
        }
    },

    activated() {
        this.queryBill()
    },

    methods: {
        init() {
            this.$store.dispatch('updateLemonBean');
            this.queryBill() // 更新订单数据
        },
        getTypeName(status) {
            if (status === 3) {
                return '充值';
            }

            if (status === 2) {
                return `${gpt40}消费`;
            }

            if (status === 1) {
                return '邀请奖励';
            }

            if (status === 4) {
                return '官方赠送';
            }

            return '未知状态';
        },

        getChangeDetail(row) {
            if (row.compute_type === 1) {
                return `+${row.count}`;
            }

            if (row.compute_type === 2) {
                return `-${row.count}`;
            }

            return '';
        },
        transTime(time) {
            // 时间戳转时间字符串
            return dayjs(time).locale('zh-cn').format('YYYY-MM-DD HH:mm');
        },
        async queryBill() {
            const loading = Loading.service({
                fullscreen: true,
                customClass: 'chat-loading',
                background: 'rgba(35, 35, 35, 0.7)'
            });

            try {
                const res = await getBillInfo();
                
                if (res.code == 0) {
                    this.billList = res.data.bill_list;
                } else {
                    this.$message({
                        message: '查询失败',
                        type: 'error',
                        customClass: 'custom-message',
                    });
                }
            }
            catch(err) {
                this.$message({
                    message: '查询失败',
                    type: 'error',
                    customClass: 'custom-message',
                });
            }
            loading.close();
        },

        tableRowClassName({row}) {
            if (row.compute_type === 1) {
                return 'addition-row';
            }

            return '';
        }
    },
    mounted() {
        this.init();
    }
}