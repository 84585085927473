import { getOrderInfo } from "@/api/order";
import { Loading } from 'element-ui';
import dayjs from 'dayjs';

export default {
    data() {
        return {
            orderList: [],
        }
    },

    activated() {
        this.queryOrder()
    },

    methods: {
        init() {
            this.queryOrder() // 更新订单数据
        },
        transStatus(status) {
            if (status === 1) {
                return '待支付';
            }

            if (status === 3) {
                return '已取消';
            }

            if (status === 2) {
                return '已完成';
            }

            return '未知状态';
        },
        transTime(time) {
            // 时间戳转时间字符串
            // let newTime = new Date(time);
            // return newTime.getFullYear() + '-' + newTime.getMonth() + '-' + newTime.getDate() + ' ' + newTime.getHours() + ':' + newTime.getMinutes()
            return dayjs(time).locale('zh-cn').format('YYYY-MM-DD HH:mm');
        },
        async queryOrder() {
            const loading = Loading.service({
                fullscreen: true,
                customClass: 'chat-loading',
                background: 'rgba(35, 35, 35, 0.7)'
            });
            try {
                const res = await getOrderInfo();

                if (res.code == 0) {
                    this.orderList = res.data.order_list;
                } else {
                    this.$message({
                        message: '查询失败',
                        type: 'error',
                        customClass: 'custom-message',
                    });
                }
            } catch(err) {
                this.$message({
                    message: '查询失败',
                    type: 'error',
                    customClass: 'custom-message',
                });
            }
            loading.close();
        }
    },
    mounted() {
        this.init();
    }
    
}