import { network } from './network';

export async function sendMessage(params) {
	const { message, botType, replyId, chat_session_id } = params;
	const res = await network({
		url: '/api/send_message',
		method: 'post',
		params: {
			message,
			chat_session_id,
			reply_id: replyId,
			bot_type: botType,
			new_version: 1
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res.data;
}

export async function favoriteMessages(opts) {
	const { message_ids } = opts;
	const res = await network({
		url: '/api/favorite_messages',
		method: 'post',
		params: {
			message_ids
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function createImageTask(params) {
	const { id, bot_type, chat_session_id, regenerte } = params;
	const res = await network({
		url: '/api/create_image_task',
		method: 'post',
		params: {
			question_id: id,
			bot_type,
			chat_session_id,
			regenerte
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res.data;
}

export async function trrigerImageAction(params) {
	const { id, bot_type, chat_session_id, action_name, action_id } = params;
	const res = await network({
		url: '/api/trriger_image_action',
		method: 'post',
		params: {
			question_id: id,
			bot_type,
			chat_session_id,
			action_name,
			action_id
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res.data;
}

export async function cancelTask(params) {
	const { task_id } = params;
	const res = await network({
		url: '/api/cancel_task',
		method: 'post',
		params: {
			task_id
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function checkTaskStatus(params) {
	const { task_id } = params;
	const res = await network({
		url: '/api/check_task_status',
		method: 'post',
		params: {
			task_id
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res.data;
}

export async function queryMessageById(params) {
	const { message_id } = params;
	const res = await network({
		url: '/api/query_message_by_id',
		method: 'post',
		params: {
			message_id
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res.data;
}

export async function deleteMessage(params) {
	const { messageId } = params;
	const res = await network({
		url: '/api/delete_message',
		method: 'post',
		params: {
			message_id: messageId
		}
	});

	return res;
}

// 获取会话列表
export async function queryChatList() {
	const res = await network({
		url: '/api/query_chat_list',
		method: 'get',
		params: {}
	});

	if (res.code !== 0 || !Array.isArray(res.data.chat_list)) {
    return [];
  }

	return res.data.chat_list;
}

// 删除会话
export async function delChatSession(params) {
	const { chat_session_id } = params;
	const res = await network({
		url: '/api/del_chat_session',
		method: 'post',
		params: {
			chat_session_id
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

// 更新会话标题
export async function updateChatSessionName(params) {
	const { chat_session_id, chat_session_name } = params;
	const res = await network({
		url: '/api/update_chat_session_name',
		method: 'post',
		params: {
			chat_session_id,
			chat_session_name
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function queryChatHistory(params) {
	const { page, pageSize, chatSessionId } = params;
	const res = await network({
		url: '/api/query_chat_history',
		method: 'post',
		params: {
			page,
			page_size: pageSize,
			chat_session_id: chatSessionId
		}
	});

	if (res.code !== 0) {
    return Promise.reject(res);
  }

  const { history_list, total_page, model_type } = res.data;

	return {
		history_list,
		total_page,
		model_type
	};
}