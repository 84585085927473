import dayjs from 'dayjs';
import { toFixedNumber } from '@/utils/util';
import { sendLoadingIcon } from '@/svg';

export default {
	props: {
		info: {
			type: Object,
			default: () => {}
		},
	},

	data() {
		return {
			sendLoadingIcon
		};
	},

	computed: {
		disabled() {
			return this.info.coupon_tag === 4 || this.info.coupon_tag === 2 || this.info.coupon_tag === 5;
		},

		name() {
			if (this.info.type === 3) {
				return '铂金会员券';
			}

			if (this.info.type === 4) {
				return '钻石会员券';
			}

			if (this.info.type === 5) {
				return '白金会员券';
			}

			return '积分购买优惠券';
		},

		btnText() {
			if (this.info.coupon_tag === 4) {
				return '已使用';
			}

    	if (this.info.coupon_tag === 0) {
    		return '未开始';
    	}

    	if (this.info.coupon_tag === 1) {
    		return '待使用';
    	}

    	if (this.info.coupon_tag === 2) {
    		return '已过期';
    	}

    	if (this.info.coupon_tag === 3) {
    		return '领取';
    	}

    	if (this.info.coupon_tag === 5) {
    		return '已领取';
    	}

    	return '已过期';
		}
	},

	methods: {
		transTime(time) {
      return dayjs(time).locale('zh-cn').format('YYYY-MM-DD');
    },

    fixDiscount(discount) {
    	if ([3, 4, 5].includes(this.info.type)) {
    		return 'VIP';
    	}

    	return toFixedNumber(discount * 10, 2);
    },

    trrigerGetBtn() {
    	if (this.info.coupon_tag === 3) {
    		this.$emit('onGetCoupon', {
    			coupon_id: this.info.coupon_id
    		});
    	}
    }
	}
};