import { mapGetters } from 'vuex';
import { eventBus } from '@/utils/event';
import Credit from '../Profile/Credit/Credit.vue';
import Convert from '../Profile/Convert/Convert.vue';
import Bill from '../Profile/Bill/Bill.vue';
import OrderList from '../Profile/Order/Order.vue';
import Counpon from '../Profile/Counpon/Counpon.vue';
import Setting from '../Profile/Setting/Setting.vue';
import Favorite from '../Profile/Favorite/Favorite.vue';
import VIP from '../Profile/VIP/Vip.vue';
import Center from '../Profile/Center/Center.vue';
import Notify from '../Profile/Notify/Notify.vue';
import WXPay from '../Profile/WXPay/WXPay.vue';
import { queryParams } from '@/utils/util';

function closest(node, className) {
	let current = node;

	while(current && current.classList && !current.classList.contains(className)) {
		current = current.parentNode;
	}

	if (current === document) {
		return null;
	}

	return current;
}

export default {
	data() {
		return {
			showMenu: false,
			creditDialogVisible: false,
      convertDialogVisible: false,
      billDialogVisible: false,
      counponDialogVisible: false,
      favoriteDialogVisible: false,
      orderListDialogVisible: false,
      settingDialogVisible: false,
      vipDialogVisible: false,
      centerDialogVisible: false,
      notifyDialogVisible: false,
      payNoticeVisible: false,
      wxpayDialogVisible: false,
      openParams: {},
      wxpayData: {
        orderNumber: '',
        qrcode: '',
        pay: 0
      },
			menuList: [
				{
          id: 'credit',
          name: '积分中心',
          iconClass: 'italk-menu__credit',
          showTips: false,
          messageCount: 0
        },

        {
          id: 'vip',
          name: '开通会员',
          iconClass: 'italk-menu__vip',
          showTips: false,
          messageCount: 0
        },

        {
          id: 'center',
          name: '个人中心',
          iconClass: 'italk-menu__center',
          showTips: false,
          messageCount: 0
        },

        {
          id: 'order',
          name: '订单中心',
          iconClass: 'italk-menu__order',
          showTips: false,
          messageCount: 0
        },

        {
          id: 'bill',
          name: '账单明细',
          iconClass: 'italk-menu__bill',
          showTips: false,
          messageCount: 0
        },

        {
          id: 'counpon',
          name: '礼包',
          iconClass: 'italk-menu__coupon',
          showTips: false,
          messageCount: 0
        },

        {
          id: 'convert',
          name: '联系客服 & 兑换码',
          iconClass: 'italk-menu__contact',
          showTips: false,
          messageCount: 0
        },

        {
          id: 'favorite',
          name: '收藏夹',
          iconClass: 'italk-menu__favorite',
          showTips: false,
          messageCount: 0
        },

        {
          id: 'notify',
          name: '消息中心',
          iconClass: 'italk-menu__notify',
          showTips: false,
          messageCount: 0
        },

        {
          id: 'setting',
          name: '设置',
          iconClass: 'italk-menu__setting',
          showTips: false,
          messageCount: 0
        },
			]
		};
	},

  computed: {
    ...mapGetters(['unReadMessages'])
  },

	methods: {
    onCreateOrder({ order_number, qrcode, pay }) {
      this.wxpayData.orderNumber = order_number;
      this.wxpayData.qrcode = qrcode;
      this.wxpayData.pay = pay;
      this.creditDialogVisible = false;
      this.wxpayDialogVisible = true;
    },

		creditOpened() {
      this.$refs.credit.init();
    },

    wxpayOpened() {
      this.$refs.wxpay.init();
    },

    billOpened() {
      this.$refs.bill.initFirstPage();
    },

    favoriteOpened() {
      this.$refs.favorite.init();
    },

    orderOpened() {
      this.$refs.orderList.initFirstPage();
    },

    counponOpened() {
      this.$refs.counpon.initFirstPage();
    },

    vipOpened() {
      this.$refs.vip.init();
    },

    notifyOpened() {
      this.$refs.notify.init();
    },

    convertOpened() {
      this.$refs.convert.init();
    },

    centerOpened() {
      this.$refs.center.init();
    },

    async loginout() {
      await this.$store.dispatch('loginOut');
      this.$router.push({
        path: '/'
      });
    },

    settingOpened() {
      this.$refs.setting.initInitialValue();
    },

    async loginout() {
    	await this.$store.dispatch('loginOut');
    	this.settingDialogVisible = false;
      this.$router.push({
        path: '/'
      });
    },

    findMenu(id) {
      for (let i = 0; i < this.menuList.length; i++) {
        if (this.menuList[i].id === id) {
          return this.menuList[i];
        }
      }

      return null;
    },

    openBill() {
      this.openMenu({
        id: 'bill'
      });
      this.wxpayDialogVisible = false;
    },

		openMenu(menu) {
      if (menu.id === 'credit') {
        this.openParams = {
          app: 'credit',
          path: 'buy'
        };
        this.creditDialogVisible = true;
      }

      if (menu.id === 'convert') {
        this.openParams = {
          app: 'convert',
          path: ''
        };
        this.convertDialogVisible = true;
      }

      if (menu.id === 'bill') {
        this.openParams = {
          app: 'bill',
          path: ''
        };
        this.billDialogVisible = true;
      }

      if (menu.id === 'counpon') {
        this.openParams = {
          app: 'counpon',
          path: ''
        };
        this.counponDialogVisible = true;
      }

      if (menu.id === 'order') {
        this.openParams = {
          app: 'order',
          path: ''
        };
        this.orderListDialogVisible = true;
      }

      if (menu.id === 'setting') {
        this.openParams = {
          app: 'setting',
          path: 'general'
        };
        this.settingDialogVisible = true;
      }

      if (menu.id === 'favorite') {
        this.openParams = {
          app: 'favorite',
          path: ''
        };
        this.favoriteDialogVisible = true;
      }

      if (menu.id === 'vip') {
        this.openParams = {
          app: 'vip',
          path: ''
        };
        this.vipDialogVisible = true;
      }

      if (menu.id === 'center') {
         this.openParams = {
          app: 'center',
          path: 'plan'
        };
        this.centerDialogVisible = true;
      }

      if (menu.id === 'notify') {
        this.openParams = {
          app: 'credit',
          path: 'system'
        };
        this.notifyDialogVisible = true;
      }
    },

		mousemove(e) {
			let desktopItem = closest(e.target, 'italk-menu__desktop-item-wrap');
			let item = desktopItem.querySelector('.italk-menu__desktop-item');
    	let itemRect = item.getBoundingClientRect();
			let offset = Math.abs(e.clientY - itemRect.top) / itemRect.height;
	    let prev = desktopItem.previousElementSibling || null;
	    let next = desktopItem.nextElementSibling || null;
	    let scale = 0.6;

	    this.resetScale();

	    if (prev) {
	    	prev = prev.querySelector('.italk-menu__desktop-item');
	      prev.style.setProperty('--scale', 1 + scale * Math.abs(offset - 1))
	    }

	    item.style.setProperty('--scale', 1 + scale)
    	
	    if (next) {
	    	next = next.querySelector('.italk-menu__desktop-item');
	      next.style.setProperty('--scale', 1 + scale * offset)
	    }
		},

		resetScale() {
			this.$el.querySelectorAll('.italk-menu__desktop-item').forEach(li => {
		    li.style.setProperty('--scale', 1)
		  });
		},

		mouseleaveDesktop() {
			this.resetScale();
			this.showMenu = false;
		},

    showDocker(e) {
      const viewportHeight = window.innerHeight;

      if (viewportHeight - e.clientY <= 30) {
        this.showMenu = true;
      }
      else {
        this.showMenu = false;
      }
    },

    openApp(params) {
      const { app, path } = params;

      this.openParams.app = app;
      this.openParams.path = path;

      if (app === 'credit') {
        this.creditDialogVisible = true;
      }

      if (app === 'convert') {
        this.convertDialogVisible = true;
      }

      if (app === 'notify') {
        this.notifyDialogVisible = true;
      }

      if (app === 'vip') {
        this.vipDialogVisible = true;
      }
    },

    addAppMessageCount(name) {
      this.menuList.forEach((menu) => {
        if (menu.id === name) {
          menu.messageCount++;
        }
      });
    },

    resetMessageCount() {
      this.menuList.forEach((menu) => {
        menu.messageCount = 0;
      });
    },

    creaditIknow() {
      this.payNoticeVisible = false;
      this.openMenu({
        id: 'vip'
      });
    },

    updateMessageCount() {
      this.resetMessageCount();
      this.unReadMessages.forEach((unReadMessage) => {
        const { app_id, path } = unReadMessage;
        
        this.addAppMessageCount(app_id);
      });
    }
	},

	mounted() {
    eventBus.$off('openSetting');
    eventBus.$on('openSetting', ({tabId}) => {
      this.settingDialogVisible = true;
      setTimeout(() => {
        this.$refs.setting.activeTab(tabId);
      }, 10);
    });
    eventBus.$off('openVIP');
    eventBus.$on('openVIP', () => {
      this.payNoticeVisible = true;
    });
    eventBus.$off('openApp');
    eventBus.$on('openApp', this.openApp);
  },

  watch: {
    unReadMessages: {
      handler() {
        this.updateMessageCount();
      }
    }
  },

	components: {
    Credit,
    Convert,
    Bill,
    Counpon,
    OrderList,
    Setting,
    Favorite,
    VIP,
    Notify,
    Center,
    WXPay
  }
};