const dayjs = require('dayjs');
import { mapGetters } from 'vuex';
import { saveSettings } from '@/api/userInfo';
import { getNotify, readMessage } from '@/api/notify';
import {
  contextIcon,
  dalleIcon,
  idIcon,
  notifyIcon,
  expandIcon,
  settingIcon,
  settingNotifyIcon,
  sendLoadingIcon,
} from '@/svg';
import { queryUrl } from '@/utils/util';

export default {
	name: 'Notify',

	props: {
    openParams: {
      type: Object,
      default: () => {
        return {
        	app: 'notify',
        	path: 'system'
        }
      }
    }
  },

	data() {
		const pagePath = this.openParams.path.split('?')[0];

		return {
			expandIcon,
			sendLoadingIcon,
			currentTab: pagePath,
			notifyLoading: true,
			notifyList: [],
			menuList: [
				{
					id: 'system',
					name: '系统消息',
					icon: notifyIcon,
					messageCount: 0
				},
			]
		};
	},

	computed: {
		...mapGetters(['setting', 'openId', 'unReadMessages']),
	},

	methods: {
		async init() {
			await this.getNotify();
			this.expandInitalNotify();
		},

		expandInitalNotify() {
			const { path } = this.openParams;
			const { notify_id } = queryUrl(path);

			if (!notify_id) {
				this.expandToggle(this.notifyList[0]);
				return;
			}

			this.notifyList.forEach((notify) => {
				if (notify_id === notify.notify_id) {
					this.expandToggle(notify);
				}
			});
		},

		async getNotify() {
			this.notifyLoading = true;

			try {
				const res = await getNotify();

				if (res.code === 0) {
					this.notifyList = this.makeNotifyRenderList(res.data.list);
				}
				else {
					this.notifyList = [];
				}
			}
			catch(err) {}
      
      this.notifyLoading = false;
    },

    chooseTab(item) {
    	this.currentTab = item.id;

			if (this.currentTab === 'system') {
				this.notifyList = [];
				this.getNotify();
			}
    },

    showNotifyUnreadTag(notifyId) {
    	let showTag = false;

    	for (let i = 0; i < this.unReadMessages.length; i++) {
    		const { app_id, path } = this.unReadMessages[i];

        if (app_id !== 'notify') {
        	continue;
        }

        const { notify_id } = queryUrl(path);

        if (notifyId === notify_id) {
        	showTag = true;
        	break;
        }
    	}

    	return showTag;
    },

    addAppMessageCount(path) {
      this.menuList.forEach((menu) => {
        if (menu.id === path) {
          menu.messageCount++;
        }
      });
    },

    resetMessageCount() {
      this.menuList.forEach((menu) => {
        menu.messageCount = 0;
      });
    },

    updateMessageCount() {
    	this.resetMessageCount();
    	this.unReadMessages.forEach((unReadMessage) => {
        const { app_id, path } = unReadMessage;
        const pagePath = path.split('?')[0];

        if (app_id !== 'notify') {
        	return;
        }

        this.addAppMessageCount(pagePath);
      });
    },

    expandToggle(item) {
    	item.expand = !item.expand;

    	if (!item.expand) {
    		return;
    	}

    	if (this.showNotifyUnreadTag(item.notify_id)) {
    		readMessage({
	    		app_id: 'notify',
	    		path: `system?notify_id=${item.notify_id}`
	    	});
	    	this.$store.dispatch('readMessages', {
	    		app_id: 'notify',
	    		path: `system?notify_id=${item.notify_id}`
	    	});
    	}
    },

    makeNotifyRenderList(notifySrcData) {
    	return notifySrcData.map((info, index) => {
    		const { notify_id, title, body_list, created_at } = info;

    		return {
    			notify_id,
    			title,
    			body_list,
    			expand: false,
    			created_at: dayjs(created_at).locale('zh-cn').format('YYYY/MM/DD HH:mm')
    		};
    	});
    }
	},

	mounted() {
		this.updateMessageCount();
	},

	watch: {
    unReadMessages: {
      handler() {
        this.updateMessageCount();
      }
    }
  },
}