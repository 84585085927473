import { favoriteMessages } from '@/api/chat';
import { favoritesIcon, sendLoadingIcon } from '@/svg';
import { successSound, payFailSound } from '@/audio';

export const favoriteMixin = {
	data() {
		return {
			favoriteMoreLoading: false
		};
	},

	computed: {
		favoritesIcon() {
			return this.favoriteMoreLoading ? sendLoadingIcon : favoritesIcon
		}
	},

	methods: {
		toggleCheckbox() {
			this.showCheckbox = !this.showCheckbox;
		},

		async favoriteOne(opts) {
			const { messageIds } = opts;

			this.updateFavoritesLoading(messageIds, true);

			await this.favorite({
				messageIds
			});

			this.updateFavoritesLoading(messageIds, false);
		},

		// 收藏消息
		async favorite(opts) {
			const { messageIds } = opts;

			try {
				const res = await favoriteMessages({
					message_ids: messageIds
				});

				if (res.code === 0) {
					this.$message({
		        message: '收藏成功',
		        type: 'success',
		        showClose: true,
		        customClass: 'custom-message',
		      });
		      this.setting.sound === '1' && successSound.play();
		      return;
				}

				this.$message({
	        message: res.message,
	        type: 'error',
	        showClose: true,
	        customClass: 'custom-message',
	      });
	      this.setting.sound === '1' && payFailSound.play();
			}
			catch(err) {}
		},

		async favoritesMore() {
			if (this.favoriteMoreLoading) {
				return;
			}

			const messageIds = [];

			this.messageList.forEach((message) => {
				if (message.checked) {
					messageIds.push(message.id);
				}
			});

			if (!messageIds.length) {
				this.$message({
	        message: '请先选择要收藏的消息',
	        type: 'error',
	        showClose: true,
	        customClass: 'custom-message',
	      });
	      this.setting.sound === '1' && payFailSound.play();
	      return;
			}

			this.favoriteMoreLoading = true;
			await this.favorite({
				messageIds
			});
			this.favoriteMoreLoading = false;
			this.resetCheckbox();
		},

		updateFavoritesLoading(ids, value) {
			this.messageList.forEach((message) => {
				if (ids.includes(message.id)) {
					message.favoriteLoading = value;
				}
			});
		},

		resetCheckbox() {
			this.showCheckbox = false;
			this.messageList.forEach((message) => {
				message.checked = false;
			});
		}
	}
};