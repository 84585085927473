import { network } from "./network";

export async function getBillInfo(opts) {
  const res = await network({
    url: '/api/query_bill',
    method: 'get',
    params: {
      page: opts.pageNumber,
      page_size: opts.page_size
    }
  });

  return res;
}