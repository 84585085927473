const privateKeyPKCS8Base64 = 'MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDAS07DT/t67FZ5K221Ycyf+CfZfsqATE7j+VuK40gHhPU1ga/ePNQ+2Msgygx5bXmgJ4Hie92tRq90ItTrMp+TSbCqY0LOj849amb71/sWDKWzULKAbRIin01f9wxWP70Ud3XH5rfhEqZ4E5NWqGy3Wy5ZjsVaJZrPYkdIcMmlUBkHnpcwaewfbJuD6CWrqFnEOorTupMhNe5SQDET6kwYCWhNG0eWYB8pTA769u8u0fBtt0JHKVEdehV9y6iEIW8fvf+LQYKq1pv47upq0jpWNVu/w7Ac5lx0rk/FWvMjZ2FKwTc9dQ++/wAhEYBek58HcxTm7O+405leZtAAOcu7AgMBAAECggEALFqSecl+k51k2sESJpa+2J0oL+onck1nmvz+yPvgtt9LogJeWvAD8tUVkWQZWX7PYYoLUv7IJHob6TlJjFCabyI9rieEFtojs5XR2EA6qZzUMZao/adq2fKNqdd1kIVWKxzc3XWRr0gafrLhNslVfjt5Zu3gBomK+k/MamCdmlQG63ZuO0lgp2Gj0h8qF4rrHhZUDT60YIUybN+Ey48qiIkuXHac2b4SL5WbDkevQSFlJXMA87vmAvVwKAIt1R3vthSNQTzNTB7N5vsfmntkc4MWMLsJCTZjPPXHacsdGwXdniJVc3BJrH/owmQK060cBSSqio2ehXCCtHraLZy+CQKBgQD2uyUhswpVEvzvPV4OC1jNMYwjIvkBiy0cOfSaPdGFlRwujhhLs/cbiSGkk3BLa95gQu+fpsnoSWnbabn/Rszpptp4J1OebTh0JcgvBIx4sYoKNeWMtItabB/uJ2QJ/oGQE+U/4vUWTGV4m/27xN1xE2c0r+BnJc/qU5bvWSVtyQKBgQDHhKJHHh6v56xsoP2AatZ+eXC7vywL2pyuqlhHfEPXBQ+jtx7Nk+Z2YCEF0fE4a9n6WmHH0gmafA+Qf9JTjR2R3Ch5f6av68BqOyqeAbyzlImwu+prlLw96yxlkfcqnPFj2ODmwKG1ETIk+ke60RLcuDpz62n2oFMql7pzsU0fYwKBgADZ0Fz3Yxe8AwdILOTbgSjj/Nm6KyWoNzWbtY46nFhblUdL0iWgrz93D5ijsYVxD8lBMdZZGuUkK6tICA4rTlWX77TuXcl0EvfDkZcn6twXsKQsX2hGzxw2tz15AU1PhV7b0rSPscyr4sWxtARLv3++U2LJIHr6lGb4dtLIeeqRAoGAB0zlGzEQ8mLJvlfNrss5+fq7k0MVe4GORh3ZiRQU72MyBU2IBQGqZx5xVuMtrmDT4wM3nLYkv9BWSXVD3ubE9DdYC8oN8VXw/nIXxxxNKU0AFMVreHBCywk14KmMn7SKaJrg79CQmi185jl6Mm3AAO+ExAUTJYEMSfyvR9hu9/0CgYBMTzBR9GM5y+tlW9+RfQovm+MCBjIRMgjzpDHu8BEX9e8vAAY9wLRK057e1NW+kuAwSFgoq3yWMiq58+t7CaCfEIMiMBNN2LSv85gaDTpwmIz3B8tBhCbpFcK50nc5Kw6wD3Wmi2OXY68JfotVHrd1Eh/HQCYEo0clGjop16LRoQ==';
const publickKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5/Bm+Rx+qafSQEEds6n7wpuYw9XXqbGcJ+vVcBC3+DTBEEr8HBp2tFyZpG6jQHWyxF3gnksAwwf6mb7wfGv8WRQy5iaz+4ioL2MS3+dh1ocO1xOzK4uq4ciMiWHB9d3jxxA0gzqUrsAD31QcPgCEUdBeVVCOGCaXXFTwv1e7d7dVqWZkI8mmJTavDq3QQ+wGCKj0r8QWIH72pBdTrhgH4e3cWAhAja5Dh+RDmWt6hmUCsdovAgbYFtdsz+Rv5Od8bgae0qw/b89JliEjgg+y9gEFKBWxusagziZVTofCn34zU8z2zU7dD0W5qp3uaH99DqEkdG56xrABa5NgsySgywIDAQAB';

async function importPKCS8PrivateKey(pkcs8Buffer) {
  const key = await window.crypto.subtle.importKey(
    'pkcs8',
    pkcs8Buffer,
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
    },
    true,
    ['decrypt']
  );
  return key;
}

async function decryptWithPrivateKey(privateKey, encryptedData) {
  const decryptedData = await window.crypto.subtle.decrypt(
    {
      name: 'RSA-OAEP',
    },
    privateKey,
    encryptedData
  );
  return new TextDecoder().decode(new Uint8Array(decryptedData));
}

function base64ToUint8Array(base64String) {
  const binaryString = atob(base64String);
  return new Uint8Array(Array.from(binaryString, (char) => char.charCodeAt(0)));
}

async function decodePicese(list) {
  const result = [];

  for (let i = 0; i < list.length; i++) {
    const importedPrivateKey = await importPKCS8PrivateKey(base64ToUint8Array(privateKeyPKCS8Base64).buffer);
    const encryptedDataFromBase64 = base64ToUint8Array(list[i]);
    const decryptedData = await decryptWithPrivateKey(importedPrivateKey, encryptedDataFromBase64.buffer);

    result.push(decryptedData);
  }

  return result;
}

export async function decode(list, callback) {
	const encryptedData = await decodePicese(list);
	
  callback(encryptedData.join(''));
}

function splitString(str) {
  const arr = [];
  const len = str.length;
  const chunkSize = 50;

  for (let i = 0; i < len; i += chunkSize) {
    arr.push(str.slice(i, i + chunkSize));
  }

  return arr;
}

async function importSPKIPublicKey(spkiBuffer) {
  const key = await window.crypto.subtle.importKey(
    'spki',
    spkiBuffer,
    {
      name: 'RSA-OAEP',
      hash: 'SHA-256',
    },
    true,
    ['encrypt']
  );
  return key;
}

async function encryptWithPublicKey(publicKey, data) {
  const encodedData = new TextEncoder().encode(data);
  const encryptedData = await window.crypto.subtle.encrypt(
    {
      name: 'RSA-OAEP',
    },
    publicKey,
    encodedData
  );
  return new Uint8Array(encryptedData);
}

function uint8ArrayToBase64(uint8Array) {
  const binaryString = Array.from(uint8Array, (byte) => String.fromCharCode(byte)).join('');

  return btoa(binaryString);
}

export async function encode(json, callback) {
  const jsonStr = JSON.stringify(json);
  const taskList = splitString(jsonStr);
  const result = [];

  for (let i = 0; i < taskList.length; i++) {
    const importedPublicKey = await importSPKIPublicKey(base64ToUint8Array(publickKey).buffer);
    const encryptedData = await encryptWithPublicKey(importedPublicKey, taskList[i]);
    const encryptedDataBase64 = uint8ArrayToBase64(encryptedData);

    result.push(encryptedDataBase64);
  }
  
  return result;
  
}