import dayjs from 'dayjs';
import { getBillInfo } from '@/api/bill';
import { gpt40, dalle3, mj, gpt35, omni, wenXin } from '@/config/model';
import { pointsIcon, shoppingCarIcon, creditRuleIcon, giftIcon, sendLoadingIcon } from '@/svg';

export default {
	name: 'Bill',

  props: {
    openParams: {
      type: Object,
      default: () => {
        return {
          app: 'bill',
          path: ''
        }
      }
    }
  },

	data() {
		return {
			sendLoadingIcon,
			loading: false,
			pageSize: 5,
			total: 1,
			currentPage: 1,
			billList: []
		}
	},

	methods: {
    getTypeName(status) {
      if (status === 1) {
        return '邀请奖励';
      }

      if (status === 2) {
        return `${gpt40}`;
      }

      if (status === 11) {
        return `${omni}`;
      }

      if (status === 12) {
        return `${wenXin}`;
      }

      if (status === 13) {
        return '红包';
      }

      if (status === 3) {
        return '充值';
      }

      if (status === 4) {
        return '官方赠送';
      }

      if (status === 5) {
        return '兑换码';
      }

      if (status === 6) {
        return `${dalle3}`;
      }

      if (status === 7) {
        return `${mj}`;
      }

      if (status === 8) {
        return `${gpt35}`;
      }

      if (status === 9) {
        return `开通会员`;
      }

      if (status === 10) {
        return `领券`;
      }

      return '未知状态';
	  },

	  transTime(time) {
      // 时间戳转时间字符串
      return dayjs(time).locale('zh-cn').format('YYYY-MM-DD HH:mm');
    },

    initFirstPage() {
    	this.total = 1;
    	this.loadPage(1);
    },

    async loadPage(pageNumber) {
    	this.loading = true;

    	try {
        const res = await getBillInfo({
        	pageNumber,
        	page_size: this.pageSize
        });
        
        if (res.code == 0) {
          this.billList = res.data.bill_list;
          this.total = res.data.total_count;
        } else {
          this.$message({
            message: '查询失败',
            type: 'error',
            showClose: true,
            customClass: 'custom-message',
          });
        }
      }
      catch(err) {
        this.$message({
          message: '查询失败',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.loading = false;
    },

    pageChange() {
    	this.loadPage(this.currentPage);
    },

    getComputeType(type) {
      return type === 1 ? '+' : '-';
    },

	  getChangeDetail(row) {
      const computeTypeTag = this.getComputeType(row.compute_type);

      if (row.unit_type === 0) {
        return `${computeTypeTag} ${row.count}积分`;
      }

      if (row.unit_type === 1) {
        return `${computeTypeTag} 1次`;
      }

      if (row.unit_type === 2) {
        return `${computeTypeTag} 铂金会员券x1`;
      }

      if (row.unit_type === 3) {
        return `${computeTypeTag} 钻石会员券x1`;
      }

      if (row.unit_type === 4) {
        return `${computeTypeTag} 白金会员券x1`;
      }

      if (row.unit_type === 5) {
        return `${computeTypeTag} 积分优惠券x1`;
      }

      return '';
    },
	}
}