const getters = {
	chatSessionId(state) {
		return state.chatSessionId;
	},

	chatSessionUseModel(state) {
		return state.chatSessionUseModel;
	},

	currentReceiveSessionId(state) {
		return state.currentReceiveSessionId;
	},

	unReadMessages(state) {
		return state.unreadMessages;
	}
};

export default getters;