import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=863a6e54&scoped=true&"
import script from "./chat.js?vue&type=script&lang=js&"
export * from "./chat.js?vue&type=script&lang=js&"
import style0 from "./chat.scss?vue&type=style&index=0&id=863a6e54&prod&lang=scss&scoped=true&"
import style1 from "./global.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "863a6e54",
  null
  
)

export default component.exports