export function sleep(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export function uuid(len = 10) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < len; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

export function toBase64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export function base64ToStr(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export function escapeHtmlStr(v) {
  var entry = { "'": "&apos;", '"': '&quot;', '<': '&lt;', '>': '&gt;' };

  v = v.replace(/(['")-><&\\\/\.])/g, function ($0) { return entry[$0] || $0; });
  v = v.replace(/\n/g, '<br>');

  return v;
}

// 组合函数
export function createComposeFunc(...fns) {
    return function composed(...args){
        // 拷贝一份保存函数的数组
        let result = args;

        for (let i = fns.length - 1; i >= 0; i--) {
            args = [fns[i](...args)];
        }

        return args[0];
    };
}

export function toNumber(val) {
    return parseFloat(val);
}

export function correctNaN(num) {
    return isNaN(num) ? 0 : num;
}

export const toNumberForce = createComposeFunc(correctNaN, toNumber);

export function toFixed(num, len) {
    let numTemp = toNumberForce(num);
    let numStr = num + '';
    let numArr = numStr.split('.');
    let isFloat = numArr.length > 1;
    let result;

    if (!isFloat) {
        // 整数
        let zeroStr = len > 0 ? '.' : '';

        for (let i = 0; i < len; i++) {
            zeroStr += '0';
        }

        result = `${numStr}${zeroStr}`;
    }
    else {
        // 浮点数
        let floatStr = numArr[1];
        let newfloatStr = len > 0 ? '.' : '';

        for (let i = 0; i < len; i++) {
            if (floatStr.charAt(i) !== '') {
                newfloatStr += floatStr.charAt(i);
            }
            else {
                newfloatStr += '0';
            }
        }

        result = `${numArr[0]}${newfloatStr}`;
    }

    return result;
}

export const toFixedNumber = createComposeFunc(correctNaN, toNumber, toFixed);

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function closest(node, className) {
  let current = node;

  while(current && current.classList && !current.classList.contains(className)) {
    current = current.parentNode;
  }

  if (current === document) {
    return null;
  }

  return current;
}

export function queryUrl(url) {
  let paramStr = url.split('?')[1];
  let result = {};

  if (!paramStr) {
      return result;
  }

  let paramList = paramStr.split('&');

  paramList.forEach((param) => {
      let key = param.split('=')[0];
      let value = param.split('=')[1];

      result[key] = value;
  });

  return result;
}

export function queryParams(paramStr) {
  let result = {};

  if (!paramStr) {
      return result;
  }

  let paramList = paramStr.split('&');

  paramList.forEach((param) => {
      let key = param.split('=')[0];
      let value = param.split('=')[1];

      result[key] = value;
  });

  return result;
}

export function toDuble(num) {
  return num < 10 ? `0${num}` : num;
}

export function getByClass(obj, sName) {
  if(obj.getElementsByClassName){
    return obj.getElementsByClassName(sName);
  }else{
    const arr = [];
    const re = new RegExp('(^|\\s)'+sName+'($|\\s)');
    const aEle = obj.getElementsByTagName('*');

    for(let i = 0; i < aEle.length; i++){
      if(re.test(aEle[i])){
        arr.push(aEle[i]);
      };
    };

    return arr;
  };
};