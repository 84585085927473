import { getAllCoupons } from '@/api/coupon';
import { Loading } from 'element-ui';
import Coupon from '@/components/Coupon/Coupon.vue';

export default {
	data() {
		return {
			renderList: []
		};
	},

	methods: {
		init() {
			this.renderMyCoupon();
		},

		async renderMyCoupon() {
			const loading = Loading.service({
        fullscreen: true,
        customClass: 'chat-loading',
        background: 'rgba(35, 35, 35, 0.7)'
      });

      try {
      	const list = await getAllCoupons();

				this.renderList = list;
      }
      catch(err) {}
			loading.close();
		},
	},

	mounted() {
		this.init();
	},

	components: {
		Coupon
	}
};