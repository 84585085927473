import Vue from 'vue';
import VueRouter from 'vue-router';
import Chat from '@/pages/Chat/Chat.vue';
import Login from '@/pages/Login/Login.vue';
import Register from '@/pages/Register/Register.vue';
import Admin from '@/pages/Admin/Admin.vue';
import Order from '@/pages/Payment/order.vue';
import OrderList from '@/pages/OrderList/OrderList.vue';
import CostDetail from '@/pages/CostDetail/CostDetail.vue';
import PaySuccess from '@/pages/PaySuccess/PaySuccess.vue';
import Building from '@/pages/Building/Building.vue';
import Coupon from '@/pages/Coupon/Coupon.vue';
import NotFound from '@/pages/404/404.vue';
import TextChat from '@/pages/TextChat/TextChat.vue';
import Favorite from '@/pages/Favorite/Favorite.vue';
import UseRule from '@/pages/UseRule/UseRule.vue';
import About from '@/pages/About/About.vue';
import Privacy from '@/pages/Privacy/Privacy.vue';
import Contact from '@/pages/Contact/Contact.vue';
import Refund from '@/pages/Refund/Refund.vue';
import Prize from '@/pages/Prize/Prize.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'chat',
    component: Chat,
    children: [
      {
        path: 'text',
        component: TextChat
      },

      {
        path: 'text/:chatId',
        component: TextChat
      }
    ]
  },
  {
    path: '/userule',
    name: 'UseRule',
    component: UseRule,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
  },
  {
    path: '/refund',
    name: 'Refund',
    component: Refund,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/prize/:convertCode',
    name: 'prize',
    component: Prize,
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound
  },
];

const router = new VueRouter({
  base: '/desktop',
  routes,
  mode: 'history'
});

// 登录态控制路由规则
router.beforeEach((to, from, next) => {
  const isLogin = store.getters.isLogin;
  const isManager = store.getters.userType === 'manager';

  if (/\/userule|\/about|\/privacy|\/contact|\/refund/.test(to.fullPath)) {
    next();
    return;
  }

  // 如果直接访问的是登录页，并且没有登录，则渲染登录页面
  if (/\/login/.test(to.fullPath) && !isLogin) {
    next();
    return;
  }

  // 如果直接访问的是登录页，已登录，则重定向到聊天页面
  if (/\/login/.test(to.fullPath) && isLogin) {
    next('/text');
    return;
  }

  // 除此之外没有登录，全部重定向到登录页去
  if (!isLogin) {
    next(`/login?redirect_url=${window.location.href}`);
    return;
  }

  if (to.fullPath === '/') {
    next('/text');
    return;
  }

  next();
})

export default router;
