import { base64ToStr } from '@/utils/util';
import { gpt35Icon, planIcon, dalleIcon, fanchuanIcon, omniIcon, wenXinIcon } from '@/svg';

export const chatGPT = base64ToStr('Q2hhdEdQVA==');
export const gpt35 = base64ToStr('R1BULTMuNQ==');
export const gpt40 = base64ToStr('R1BULTQuMA==');
export const omni = base64ToStr('R1BULTRv');
export const dalle3 = base64ToStr('REFMTMK3RS0z');
export const mj = base64ToStr('TWlkam91cm55');
export const wenXin = '文心一言 3.5';
export const openai = base64ToStr('T3BlbkFJ');
export const ai = base64ToStr('QUk=');
export const modelTypes = [
	// 文心一言
	// {
	// 	type: 100,
	// 	title: wenXin,
	// 	des: '百度大模型，免费',
	// 	firstTitle: '文心一言',
	// 	subTile: '3.5',
	// 	switchIcon: wenXinIcon
	// },

	// 3.5
	{
		type: 0,
		title: gpt35,
		des: base64ToStr('6Z2e5bi46YCC5ZCI5pel5bi45Lu75Yqh'),
		firstTitle: chatGPT,
		subTile: base64ToStr('My41'),
		switchIcon: gpt35Icon
	},

	// 4.0
	{
		type: 1,
		title: gpt40,
		des: base64ToStr('5pyA5pm66IO944CB6IO95Yqb5pyA5by655qE5qih5Z6L'),
		firstTitle: chatGPT,
		subTile: base64ToStr('NC4w'),
		switchIcon: planIcon
	},

	// omni
	{
		type: 50,
		title: omni,
		des: base64ToStr('T3BlbkFJ5paw5LiA5Luj5peX6Iiw5qih5Z6L77yM5pu05b+r77yM5pu05L6/5a6c77yM5pu05pm66IO9'),
		firstTitle: chatGPT,
		subTile: base64ToStr('NG8='),
		switchIcon: omniIcon
	},

	// dalle3
	{
		type: 2,
		title: dalle3,
		des: base64ToStr('T3BlbkFJ5pyA5pawQUnnu5jnlLvmqKHlnos='),
		firstTitle: base64ToStr('REFMTMK3RQ=='),
		subTile: '3.0',
		switchIcon: dalleIcon
	},

	// midjourny
	{
		type: 3,
		title: mj,
		des: base64ToStr('5Lia55WM5pyA5LyY56eA55qEQUnnu5jnlLvmqKHlnos='),
		firstTitle: mj,
		subTile: '',
		switchIcon: fanchuanIcon
	},
];