import { network } from './network';

export async function getNotify() {
	const res = await network({
		url: '/api/get_notify',
		method: 'get',
		params: {}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function readMessage(opts) {
	const { app_id, path } = opts;
	const res = await network({
		url: '/api/read_message',
		method: 'post',
		params: {
			app_id,
			path
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}