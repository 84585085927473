import { beanIcon } from '@/svg';

export default {
	data() {
		return {
			beanIcon
		};
	},

	methods: {
		goHome() {
			this.$router.push({
				path: '/'
			});
		},

		goCostDetail() {
			this.$router.push({
				path: '/cost_detail'
			});
		}
	}
};