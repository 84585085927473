import { network } from './network';

export async function login(params) {
	const { username, password, code } = params;
	const res = network({
		url: '/api/login',
		method: 'post',
		params: {
			username,
			password,
			code
		}
	});

	return res;
}

export async function loginOut() {
	const res = network({
		url: '/api/login_out',
		method: 'get',
		params: {}
	});

	return res;
}

export async function checkLogin() {
	const res = network({
		url: '/api/check_login',
		method: 'get',
		params: {}
	});

	return res;
}

export async function getTicket(params) {
	const { invite_code } = params;
	const res = network({
		url: '/api/qrcode',
		method: 'get',
		params: {
			invite_code: invite_code || ''
		}
	});

	return res;
}

export async function checkLoginStatus(params) {
	const { login_tag } = params;
	const res = network({
		url: '/api/login_status',
		method: 'post',
		params: {
			login_tag
		}
	});

	return res;
}

export async function canLogin() {
	const res = network({
		url: '/api/can_login',
		method: 'get',
		params: {}
	});

	return res;
}

export async function getInviteLink() {
	try {
		const res = await network({
			url: '/api/get_invite_link',
			method: 'get',
			params: {}
		});

		if (res.code !== 0) {
			return '';
		}

		return res.data.invite_link || '';
	} catch(err) {
		return '';
	}
}