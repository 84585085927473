import { getCouponsBuyPage } from '@/api/coupon';
import dayjs from 'dayjs';
import { toFixedNumber } from '@/utils/util';
import { sendLoadingIcon } from '@/svg';
import Coupon from '@/components/Coupon/Coupon.vue';

export default {
	name: 'Counpon',

  props: {
    openParams: {
      type: Object,
      default: () => {
        return {
          app: 'counpon',
          path: ''
        }
      }
    }
  },

	data() {
		return {
			sendLoadingIcon,
			list: [],
			loading: true,
			pageSize: 12,
			total: 1,
			currentPage: 1,
		}
	},

	methods: {
		initFirstPage() {
    	this.total = 1;
    	this.loadPage(1);
    },

		pageChange() {
    	this.loadPage(this.currentPage);
    },

		async loadPage(pageNumber) {
    	this.loading = true;

    	try {
        const res = await getCouponsBuyPage({
        	pageNumber,
        	page_size: this.pageSize
        });
        
        if (res.code == 0) {
          this.list = res.data.list;
          this.total = res.data.total_count;
        } else {
        	this.list = [];
          this.$message({
            message: '查询失败',
            type: 'error',
            showClose: true,
            customClass: 'custom-message',
          });
        }
      }
      catch(err) {
        this.$message({
          message: err.message,
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.loading = false;
    },
	},

  components: {
    Coupon
  }
}