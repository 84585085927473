import { mapGetters } from 'vuex';
import cookie from 'cookie-utils-js';
import NewChat from '@/components/NewChat/NewChat.vue';
import ChatList from '@/components/ChatList/ChatList.vue';
import Plan from '@/components/Plan/Plan.vue';
import { getPushMessages } from '@/api/app';
import { isChatSessionPath, isEmptyChatSessionPath } from '@/utils/tool';
import { eventBus } from '@/utils/event';
import { isMobile, sleep, queryParams } from '@/utils/util';
import { getNotify } from '@/api/notify';
import { switchSound, paySound, payFailSound, notifySound } from '@/audio';
import { needFeedback, delayFeedback, giveAFeedback } from '@/api/feedback';

export default {
  name: 'Chat',

  data() {
    const sliderSwitch = cookie.get(`slider_switch`) || '1';
    
    return {
      isMobile,
      showSlider: sliderSwitch === '1',
      showSliderActive: false,
      hideSliderActive: false,
      notifyDialogVisible: false,
      notifyText: '',
      notifyId: '',
      feedbackStartValue: null,
      feedbackTextValue: '',
      feedbackLoading: false,
      showFeedback: false,
      messageList: []
    }
  },

  beforeCreate() {
    if (isChatSessionPath(this.$route.path)) {
      this.$store.dispatch('updateChatSessionId', this.$route.params.chatId);
      return;
    }

    if (isEmptyChatSessionPath(this.$route.path)) {
      this.$store.dispatch('updateChatSessionId', '');
    }
  },

  computed: {
    ...mapGetters(['unReadMessages', 'setting'])
  },

  methods: {
    mouseenterToggleBtn() {
      if (this.showSlider) {
        this.showSliderActive = true;
        return;
      }
    },

    mouseleaveToggleBtn() {
      if (this.showSlider) {
        this.showSliderActive = false;
        return;
      }
    },

    toggleSlider() {
      this.showSlider = !this.showSlider;

      const maxAge = 20 * 365 * 24 * 60 * 60;

      cookie.set(`slider_switch`, this.showSlider ? '1' : '0', {
        'max-age': maxAge,
        path: '/',
        secure: true,
        sameSite: 'Strict'
      });

      if (this.setting.sound === '1') {
        switchSound.play();
      }
    },

    openNewChat() {
      eventBus.$emit('sessionChangeForceStop');
      this.$store.dispatch('updateChatSessionId', '');
      this.$router.push({
        path: `/text`
      });
      eventBus.$emit('initChatSessionMessageList');
    },

    async getFeedback() {
      try {
        const res = await needFeedback();

        this.showFeedback = res.data.need_feedback;
        // this.showFeedback = true;
      }
      catch(err) {}
    },

    async submitFeedback() {
      if (this.feedbackStartValue === 0) {
        this.$message({
          message: '请先选择评分',
          showClose: true,
          type: 'error',
          duration: 1000,
          customClass: 'custom-message',
        });
        this.setting.sound === '1' && payFailSound.play();
        return;
      }

      this.feedbackLoading = true;
      try {
        const res = await giveAFeedback({
          star: this.feedbackStartValue,
          content: this.feedbackTextValue
        });

        this.$message({
          message: res.message,
          showClose: true,
          type: 'success',
          duration: 3000,
          customClass: 'custom-message',
        });
        this.setting.sound === '1' && paySound.play();
        this.closeFeedback();
      }
      catch(err) {}
      this.feedbackLoading = false;
    },

    closeFeedback() {
      this.showFeedback = false;
    },

    nextTimeFeedback() {
      this.closeFeedback();
      delayFeedback();
    },

    neverNotify() {
      const maxAge = 20 * 365 * 24 * 60 * 60;

      cookie.set(`notify_${this.notifyId}`, '1', {
        'max-age': maxAge,
        path: '/',
        secure: true,
        sameSite: 'Strict'
      });
      this.notifyDialogVisible = false;
    },

    async receivePushMessage() {
      try {
        const res = await getPushMessages();

        if (res.code === 0) {
          this.messageList = res.data.messages;
          this.$store.dispatch('updateUnReadMessages', res.data.no_read_messages);
        }
        else {
          this.messageList = [];
          this.$store.dispatch('updateUnReadMessages', []);
        }

        this.pushMessage();
      }
      catch(err) {}

      await sleep(60000);
      this.receivePushMessage();
    },

    async pushMessage() {
      if (!this.messageList.length) {
        return;
      }

      for (let i = 0; i < this.messageList.length; i++) {
        const { title, description, app_id, path } = this.messageList[i];
        const currentNotify = this.$notify.info({
          customClass: `push_message app_name_${app_id}`,
          title,
          message: description,
          duration: 0,
          onClick() {
            eventBus.$emit('openApp', {
              app: app_id,
              path
            });
            currentNotify.close();
          }
        });

        this.setting.sound === '1' && notifySound.play();
        await sleep(100);
      }
    }
  },

  mounted() {
    this.getFeedback();
    this.receivePushMessage();
  },

  beforeDestroy() {
    // eventBus.$off();
  },

  components: {
    NewChat,
    ChatList,
    Plan,
  }
}
