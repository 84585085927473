import { register } from '@/api/register';

export default {
	name: 'register',
	data() {
    return {
      userName: '',
      password: '',
      code: '',
      passwordRepeat: '',
      errorMsg: '',
      loading: false,
      imgCodeUrl: '/api/code?1',
    };
  },

  methods: {
  	async submit() {
      const checkResult = this.checkInput();

      if (checkResult.code !== 0) {
        this.errorMsg = checkResult.message;
        return;
      }

      this.errorMsg = '';
      this.loading = true;

      try {
        const res = await register({
          username: this.userName,
          password: this.password,
          code: this.code
        });

        this.loading = false;

        if (res.code === 0) {
          this.$message({
            message: '注册成功',
            type: 'success'
          });
          this.goLogin();
          return;
        }

        if (res.code === 102) {
          this.errorMsg = '用户已存在';
          return;
        }
        
        this.errorMsg = res.message;
      } catch(err) {
        this.$message.error(err.message || '出错了');
      }

      this.loading = false;
    },

    checkInput() {
      const userNameRule = /^(?![0-9_-])[a-zA-Z0-9_-]*$/;

      if (this.userName === '' || this.password === '') {
        return {
          code: 1,
          message: '用户名或密码不能为空'
        };
      }

      if (!userNameRule.test(this.userName)) {
        return {
          code: 1,
          message: '用户名必须以字母开头，且只能包含数字、字母、-、_'
        };
      }

      if (this.userName.length < 4 || this.userName.length > 10) {
        return {
          code: 1,
          message: '用户名长度只能为4-10个字母'
        };
      }

      if (this.password !== this.passwordRepeat) {
        return {
          code: 1,
          message: '密码不一致'
        };
      }

      return {
        code: 0,
        message: 'success'
      };
    },

  	goLogin() {
  		this.$router.push({
  			path: '/login'
  		});
  	},

    refreshCode() {
      this.imgCodeUrl += 'a';
    },
  }
}