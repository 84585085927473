import { mapGetters } from 'vuex';
import { modelTypes } from '@/config/model';
import { eventBus } from '@/utils/event';
import { modelSwitchArrowDown, gpt35Icon, planIcon, modelCheck, modelChecked } from '@/svg';

export default {
	name: 'TextModelSwitch',

	data() {
		return {
			modelSwitchArrowDown,
			gpt35Icon,
			planIcon,
			modelChecked,
			modelCheck,
			modelTypes,
			visible: false
		};
	},
	
	computed: {
		...mapGetters(['chatSessionUseModel', 'chatSessionId']),
		chooseModelInfo() {
			for (let i = 0; i < this.modelTypes.length; i++) {
				if (this.modelTypes[i].type === this.chatSessionUseModel) {
					return this.modelTypes[i];
				}
			}
		}
	},

	methods: {
		chooseModel(item) {
			if (!this.chatSessionId) {
				this.$store.dispatch('updateChatSessionUseModel', item.type);
				this.visible = false;
				return;
			}

			// 当前会话用的模型与选择的模型不一样，就跳转到新建聊天去
			if (this.chatSessionUseModel !== item.type && this.chatSessionId) {
				eventBus.$emit('sessionChangeForceStop');
				this.$store.dispatch('updateChatSessionUseModel', item.type);
				this.$store.dispatch('updateChatSessionId', '');
				this.$router.push({
	        path: `/text`
	      });
	      eventBus.$emit('initChatSessionMessageList');
			}

			this.visible = false;
		}
	}
}

