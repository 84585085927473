import { mapGetters } from 'vuex';
import { chatGPT, mj } from '@/config/model';
import Reply from '@/components/Reply/Reply.vue';
import { maxIcon, sendArrowIcon, stopAnswerIcon, sendLoadingIcon, minIcon, regenerateIcon, settingIcon } from '@/svg';

export default {
	name: 'MessageInput',

	props: {
		status: {
			type: String,
			default: 'waitInput' // waitInput | outputting | sending
		},

		answerFail: {
			type: Boolean,
			default: false
		},

		disabled: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			sendArrowIcon,
			settingIcon,
			maxIcon,
			chatGPT,
			mj,
			stopAnswerIcon,
			sendLoadingIcon,
			minIcon,
			regenerateIcon,
			inputFocus: false,
			message: '',
			maxView: false,
			replyMessage: '',
			replyId: '',
		};
	},

	computed: {
    ...mapGetters(['chatSessionUseModel']),
  },

	methods: {
		inputFocusHanlder() {
			this.inputFocus = true;
		},

		inputBlurHanlder() {
			this.inputFocus = false;
		},

		toggleMaxView() {
			if (this.disabled) {
				return;
			}
			
			this.maxView = !this.maxView;

			setTimeout(() => {
				this.maxView ? this.$refs.maxInput.focus() : this.$refs.minInput.focus();
			}, 0);
		},

		setReplyInfo(replyRessage) {
			const { content, id } = replyRessage;

			this.replyMessage = content;
			this.replyId = id;
		},

		inputKeydown(e) {
			if (this.status === 'outputting' || this.status === 'sending') {
				return;
			}

			if (e.keyCode === 13) {
				this.submitMessage();
			}
		},

		stopReceiveMessage() {
			if (this.disabled) {
				return;
			}

			this.$emit('onStopReceiveMessage');
		},

		setMessage(message) {
			this.message = message;
		},

		submitMessage() {
			if (this.disabled) {
				return;
			}

			this.$emit('onSubmitMessage', {
				message: this.message,
				replyMessage: this.replyMessage,
				replyId: this.replyId
			});
		},

		clearReply() {
			this.replyMessage = '';
			this.replyId = '';
			this.focus();
		},

		reset() {
			this.replyMessage = '';
			this.replyId = '';
			this.message = '';
		},

		regenerate() {
			this.$emit('onRegenerate');
		},

		openSetting(tabId) {
			this.$emit('onOpenSetting', {
				tabId
			});
		},

		focus() {
			this.maxView ? this.$refs.maxInput && this.$refs.maxInput.focus() : this.$refs.minInput && this.$refs.minInput.focus();
		}
	},

	mounted() {
		setTimeout(() => {
			this.focus();
		}, 500);
	},

	components: {
		Reply
	}
}
