import _ from 'lodash';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { vipRuleIcon } from '@/svg';
import { v0Plan } from '../../VIP/plan/v0';
import { v1Plan } from '../../VIP/plan/v1';
import { v2Plan } from '../../VIP/plan/v2';
import { v3Plan } from '../../VIP/plan/v3';
import { gpt35, gpt40, mj, dalle3, omni, wenXin } from '@/config/model';
import { pointsIcon, shoppingCarIcon, creditRuleIcon, giftIcon, sendLoadingIcon } from '@/svg';

export default {
	data() {
		return {
			vipRuleIcon,
			sendLoadingIcon,
			gpt35,
			gpt40,
			mj,
			dalle3,
			wenXin,
			omni,
			loading: true,
			plans: [
				{
					...v0Plan
				},
				{
					...v1Plan
				},
				{
					...v2Plan
				},
				{
					...v3Plan
				}
			]
		};
	},

	computed: {
		...mapGetters(['vipLevel', 'vipRulesInfo', 'vipExpireDate']),

		planName(type) {
			const planNames = {
				0: '普通会员',
				1: '铂金会员',
				2: '钻石会员',
				3: '白金会员'
			};

			return planNames[this.vipLevel];
		},

		expireDate() {
			if (this.vipExpireDate) {
				return dayjs(this.vipExpireDate).locale('zh-cn').format('YYYY-MM-DD HH:mm');
			}
			
			return '无限期';
		},

		useLevel() {
			return this.vipLevel;
		}
	},

	methods: {
		async init() {
			this.loading = true;
			await this.$store.dispatch('updateUserInfo');
			this.loading = false;
		},

		isNull(value) {
			return _.isNull(value);
		}
	}
};