import Plan from './Plan/Plan.vue';
import { vipIcon } from '@/svg';

export default {
	props: {
    openParams: {
      type: Object,
      default: () => {
        return {
          app: 'center',
          path: 'plan'
        }
      }
    }
  },

	data() {
		const pagePath = this.openParams.path.split('?')[0];

		return {
			currentTab: pagePath,
			menuList: [
				{
					id: 'plan',
					name: '会员权益',
					icon: vipIcon
				},
			]
		};
	},

	methods: {
		init() {
			this.$refs.plan.init();
		},

		chooseTab(item) {
			if (item.id === 'plan') {
				this.$refs.plan.init();
			}
		}
	},

	components: {
		Plan
	}
};