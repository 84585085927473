import { network } from './network';

export async function register(params) {
	const { username, password, code } = params;
	const res = await network({
		url: '/api/register',
		method: 'post',
		params: {
			username,
			password,
			code
		}
	});

	return res;
}