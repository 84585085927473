import { network } from './network';

export async function giveAFeedback(params) {
	const { content, star } = params;
	const res = await network({
		url: '/api/feedback',
		method: 'post',
		params: {
			content,
			star
		}
	});

	return res;
}

export async function needFeedback() {
	const res = await network({
		url: '/api/need_feedback',
		method: 'get',
		params: {}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}

export async function delayFeedback() {
	const res = await network({
		url: '/api/delay_feedback',
		method: 'get',
		params: {}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}