import Vue from 'vue';
import Vuex from 'vuex';
import user from './user';
import chat from './chat';
import getters from './getters';
import { appVersion } from '@/config/version';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		version: appVersion
	},
	modules: {
		user,
		chat
	},
	getters,
	strict: false
})
