import DeviceDetector from 'device-detector-js';
import { mapGetters } from 'vuex';
import moment from 'moment';
import download from 'downloadjs';
import UserPhoto from '@/components/UserPhoto/UserPhoto.vue';
import {
		copyIcon,
		delIcon,
		reGenerateIcon,
		replyIcon,
		imgDrawLoadingIcon,
		sendLoadingIcon,
		downloadIcon,
		imgSkeletonIcon,
		copyDoneIcon,
		favoritesIcon,
		choosIcon
} from '@/svg';
import { successSound } from '@/audio';
import { findLastTextNode } from '@/utils/tool';
import { base64ToStr } from '@/utils/util';
import { mj, wenXin } from '@/config/model';

const deviceDetector = new DeviceDetector();
const iconDelay = 3000;
const device = deviceDetector.parse(window.navigator.userAgent);

export default {
	name: 'Message',

	props: {
		dataSource: {
			type: Object,
			default: () => {}
		},

		preview: {
			type: Boolean,
			default: false
		},

		showCheckbox: {
			type: Boolean,
			default: false
		}
	},

	data() {
		return {
			copyDoneIcon,
			delIcon,
			reGenerateIcon,
			imgSkeletonIcon,
			replyIcon,
			choosIcon,
			imgDrawLoadingIcon,
			sendLoadingIcon,
			isMac: Boolean(device && device.os && device.os.name === 'Mac'),
			imgLoading: true,
			copyDone: false,
			downloadDone: false,
			checked: false,
		};
	},

	computed: {
		...mapGetters(['chatSessionUseModel', 'setting', 'vipLevel', 'nickname', 'photo']),
		userPhotoType() {
			if (this.dataSource.role === 'user') {
				return -1;
			}

			return this.dataSource.modelType;
		},

		favoritesIcon() {
			return this.dataSource.favoriteLoading ? sendLoadingIcon : favoritesIcon;
		},

		copyIcon() {
			if (this.copyDone) {
				return copyDoneIcon;
			}

			return copyIcon;
		},

		downloadIcon() {
			if (this.downloadDone) {
				return copyDoneIcon;
			}

			return downloadIcon;
		},

		userName() {
			if (this.dataSource.role === 'user') {
				return this.nickname || '您';
			}

			if (this.dataSource.modelType === 3) {
				return mj;
			}

			if (this.dataSource.modelType === 100) {
				return '文心一言';
			}

			return base64ToStr('Q2hhdEdQVA==');
		},

		copyTips() {
			if (this.dataSource.role === 'bot' && this.dataSource.modelType === 2) {
				return '复制提示词';
			}
			
			return '复制';
		},

		isText() {
			return [0, 1, 50, 100].includes(this.dataSource.modelType);
		},

		isPic() {
			return this.dataSource.modelType === 2 || this.dataSource.modelType === 3;
		}
	},

	methods: {
		chooseCheckbox() {
			this.dataSource.checked = !this.dataSource.checked;
		},

		loadImg() {
			const img = document.createElement('img');

			img.onload = () => {
				this.imgLoading = false;
			};
			img.src = this.dataSource.content.url;
		},

		timeFormat(stamp) {
			return moment(stamp).format('YYYY年MM月DD日 HH时mm分');
		},

		showActionRow(names) {
			const buttons = this.dataSource.content.actionButtons;

			for (let i = 0; i < buttons.length; i++) {
				if (names.includes(buttons[i].alias)) {
					return true;
				}
			}

			return false;
		},

		// 正方形图片
		isImgSquare(size) {
			if (!size) {
				return true;
			}

			return size === '1024x1024';
		},

		favorites() {
			if (this.dataSource.favoriteLoading) {
				return;
			}
			
			this.$emit('onFavorite', {
				messageIds: [this.dataSource.id]
			});
		},

		toggleCheckbox() {
			this.dataSource.checked = true;
			this.$emit('onToggleCheckbox', {
				messageId: this.dataSource.id
			});
		},

		copy() {
			const textarea = document.createElement('textarea');

			if (this.copyDone) {
				return;
			}
      
			if (this.dataSource.modelType === 2 || this.dataSource.modelType === 3) {
				textarea.value = this.dataSource.content.revised_prompt;
			}
			else {
				textarea.value = this.dataSource.sourceContent;
			}
      
      this.copyDone = true;

      setTimeout(() => {
      	this.copyDone = false;
      }, iconDelay);
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();

      if (this.setting.sound === '1') {
      	successSound.play();
      }
      
      this.$message({
        message: '复制成功',
        type: 'success',
        showClose: true,
        customClass: 'custom-message',
      });
		},

		downLoadImage() {
			if (this.downloadDone) {
				return;
			}

			this.$message({
        message: '下载中，请稍后...',
        type: 'success',
        showClose: true,
        customClass: 'custom-message',
      });

      if (this.setting.sound === '1') {
      	successSound.play();
      }
      
			download(this.dataSource.content.real_url);
			this.downloadDone = true;
      setTimeout(() => {
      	this.downloadDone = false;
      }, iconDelay);
		},

		previewImg(content) {
			console.log('content:', content);
			this.$hevueImgPreview(content.real_url);
		},

		trigerMJAction(actionInfo) {
			this.$emit('onAction', {
				actionName: actionInfo.value,
				actionId: actionInfo.actionId
			});
		},

		reply() {
			const contentText = this.$el.querySelector('.chat__message-text').textContent;

			this.$emit('onReply', {
				id: this.dataSource.id,
				content: contentText.replace(/\n/g, ''),
			});
		},

		jumpTo() {
			this.$emit('jumpToTragetMessage', {
				messageId: this.dataSource.replyMessage.id
			});
		},

		del() {
			this.$emit('onDelete', {
				messageId: this.dataSource.id
			});
		},

		regenerate() {
			this.$emit('onRegenerate', {
				messageId: this.dataSource.id
			});
		},

		updateInsertAnimation() {
			const root = this.$el.querySelector('.chat__message-text');
			const lastTextNode = findLastTextNode(root);
			const parentNode = lastTextNode ? lastTextNode.parentNode : root;

			parentNode && parentNode.classList.add('chat__answer-insert-animation');
		},

		refreshContent() {
			const isImage = [1, 2].includes(this.dataSource.contentType);

			if (!isImage) {
				return;
			}

			this.loadImg();
		}
	},

	watch: {
		'dataSource.content': {
			handler() {
				this.refreshContent();
			},
			immediate: true,
			deep: true
		}
	},

	components: {
		UserPhoto,
	}
};