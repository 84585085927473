import { mapGetters } from 'vuex';
import { marked }  from 'marked';
import hljs from 'highlight.js';
import TextModelSwitch from '@/components/TextModelSwitch/TextModelSwitch.vue';
import MessageInput from '@/components/MessageInput/MessageInput.vue';
import QustionItems from '@/components/QustionItems/QustionItems.vue';
import Message from '@/components/Message/Message.vue';
import { chatGPT, mj, wenXin } from '@/config/model';
import { botIcon, goTopArrowIcon, sendLoadingIcon, regenerateIcon, fanchuanIcon, cancelIcon, wenXinIcon } from '@/svg';
import { escapeHtmlStr, uuid, closest, getByClass, sleep } from '@/utils/util';
import { sendMessage, queryChatHistory, deleteMessage } from '@/api/chat';
import { checkLogin } from '@/api/login';
import { decode } from '@/utils/secret';
import { eventBus } from '@/utils/event';
import { findLastTextNode, getPos } from '@/utils/tool';
import { regenerateMixin } from './regenerate';
import { messageInputMixin } from './input';
import { receiveSound, tapSound, successSound } from '@/audio';
import { messageMixin } from './message';
import { imageAnswerMixin } from './image_answer';
import { imageActionMixin } from './image_action';
import { findActionInfoByValue } from './action';
import { favoriteMixin } from './favorite';
import 'highlight.js/styles/atom-one-dark.css';

const errorMsg = '对不起，发生了一些错误，请重新提问。';

export default {
	name: 'TextChat',

	mixins: [regenerateMixin, messageInputMixin, messageMixin, imageAnswerMixin, imageActionMixin, favoriteMixin],

	data() {
		return {
			botIcon,
			goTopArrowIcon,
			sendLoadingIcon,
			fanchuanIcon,
			regenerateIcon,
			cancelIcon,
			loading: false,
			mj,
			firstPageLoadFail: false,
			messageList: [],
			totalPage: 1,
			showGoTopBtn: false,
			inputStatus: 'waitInput',
			currentPage: 1,
			autoScroll: true,
			pageLoading: false,
			stopVisible: true,
			pageSize: 20,
			firstPageInitial: false,
			delDialogVisible: false,
			answerFail: false,
			delLoading: false,
			showCheckbox: false,
			deleteMessageId: '',
			sessionId: '',
			chatGPT,
			currentReceiveSessionInfo: {
				chat_session_id: '',
				isNewAnswer: false
			}
		};
	},

	computed: {
		...mapGetters(['chatSessionId', 'chatSessionUseModel', 'setting', 'userType']),

		isNewChat() {
			return !Boolean(this.chatSessionId);
		},

		welcomeIcon() {
			if ([0, 1, 2, 50].includes(this.chatSessionUseModel)) {
				return botIcon;
			}

			if (this.chatSessionUseModel === 3) {
				return fanchuanIcon;
			}

			if (this.chatSessionUseModel === 100) {
				return wenXinIcon;
			}
		},

		footerTips() {
			if ([0, 1, 2, 50].includes(this.chatSessionUseModel)) {
				return `${chatGPT}&nbsp;可能会发生错误，请关注错误信息。`;
			}

			if (this.chatSessionUseModel === 100) {
				return `${wenXin}&nbsp;可能会发生错误，请关注错误信息。`;
			}

			if (this.chatSessionUseModel === 3) {
				return `${mj}&nbsp;可能会发生错误，请关注错误信息。`;
			}

			return '';
		},

		showQustionItems() {
			if (this.isNewChat && !this.messageList.length) {
				return true;
			}

			return false;
		}
	},

	methods: {
		init() {
			// 初始化markdown渲染器
			this.initMarkdownRender();

			// 初始化当前会话的第一页数据
			this.initChatSessionMessageList();

			// 强制把之前还未结束的回复终止
			eventBus.$off('sessionChangeForceStop');
			eventBus.$on('sessionChangeForceStop', () => {
				this.showCheckbox = false;

				if (this.isTextChat()) {
	    		this.stopAnswerEvent();
	    	}
	      
	      if (this.isImageChat()) {
	      	this.stopDrawImageTask();
	      }

				// 如果消息未发送完成前强制终止，刷新会话列表，可能不能获取到此消息对应的会话，忽略这种极端情况
				if (this.currentReceiveSessionInfo.isNewAnswer) {
					eventBus.$emit('refreshChatSessionList');
				}
			});

			// 这里的事件会在左侧切换会话的时候触发
			eventBus.$off('initChatSessionMessageList');
			eventBus.$on('initChatSessionMessageList', () => {
				this.initChatSessionMessageList();
			});
		},

		reload() {
			this.initChatSessionMessageList();
		},

		initChatSessionMessageList() {
			this.showCheckbox = false;
			this.resetStatus();
			this.initMessageList();
		},

		resetStatus() {
			this.loading = false;
			this.sessionId = uuid();
			this.firstPageLoadFail = false;
			this.messageList = [];
			this.totalPage = 1;
			this.showGoTopBtn = false;
			this.inputStatus = 'waitInput';
			this.currentPage = 1;
			this.autoScroll = true;
			this.pageLoading = false;
			this.answerFail = false;
			this.firstPageInitial = true;
			this.delDialogVisible = false;
			this.delLoading = false;
			this.deleteMessageId = '';
			this.$refs.messageInput && this.$refs.messageInput.reset();
		},

		// 初始化当前会话的第一页数据
		async initMessageList() {
			// 如果没有选中会话id，就不需要加载
			if (!this.chatSessionId) {
				return;
			}

			this.loading = true;
			this.firstPageInitial = false;

			try {
				const { model_type } = await this.loadPage();

				this.$store.dispatch('updateChatSessionUseModel', model_type);
				this.loading = false;
				this.firstPageLoadFail = false;
				this.scrollToEnd();
				this.inputFocus();
				setTimeout(() => {
					this.firstPageInitial = true;
				}, 10);
			}
			catch(err) {
				this.loading = false;

				// 异步任务终止
				if (err.code === 150) {
					return;
				}

				this.firstPageLoadFail = true;
				this.$message({
	        message: err.message,
	        type: 'error',
	        showClose: true,
	        customClass: 'custom-message',
	      });
			}
		},

		onOpenSetting({tabId}) {
			eventBus.$emit('openSetting', {
				tabId
			});
		},

		async loadPage() {
			const currentSessionId = this.sessionId;
			const { history_list, total_page, model_type } = await queryChatHistory({
        page: this.currentPage,
        pageSize: this.pageSize,
        chatSessionId: this.chatSessionId
      });

      // 这里随机id发生了变化，说明会话发发生了变动，在这里拿到的消息需要遗弃，所以不执行后面的逻辑
			if (currentSessionId !== this.sessionId) {
				return Promise.reject({
					code: 150,
					data: {},
					message: '异步任务终止'
				});
			}

			let renderList = this.makeHistoryRenderList(history_list, model_type);

      this.messageList = renderList.concat(this.messageList);
      this.totalPage = total_page;

      return {
      	model_type
      };
		},

		handleScroll() {
    	if (!this.chatSessionId) {
				return;
			}

    	this.autoScroll = this.pageTouchBottom();
    	this.loadHistoryList();
    },

    // 翻页加载
    async loadHistoryList() {
    	const scrollView = this.$el.querySelector('.text-chat__scroll-view');
    	const scrollTop = scrollView.scrollTop;

    	if (scrollTop !== 0 || !this.firstPageInitial) {
    		return;
    	}

      if (this.pageLoading || this.currentPage >= this.totalPage) {
        return;
      }

      this.currentPage++;

      if (this.currentPage > this.totalPage) {
        return;
      }

      const firstMessageId = this.messageList[0].id;

      this.pageLoading = true;

      try {
      	await this.loadPage();
      	this.scrollToMessage(firstMessageId);
      }
      catch(err) {
      	// 异步任务终止
				if (err.code === 150) {
					return;
				}
				this.currentPage--;
      }
      
      this.pageLoading = false;
    },

		getReplyMessageContent(info) {
      if (!info) {
        return null;
      }

      const { content, message_id } = info;
      const div = document.createElement('div');

      div.innerHTML = marked(content);

      return {
        content: div.textContent,
        id: message_id
      };
    },

		makeHistoryRenderList(historyList, model_type) {
      const result = [];

      historyList.forEach((item) => {
        if (item.type === 'question') {
          result.push({
          	id: item.message_id + '',
					  role: 'user',
					  contentType: item.content_type,
					  type: item.type,
					  status: 'ready',
					  createdAt: item.created_at,
					  replyMessage: this.getReplyMessageContent(item.reply_message),
					  sourceContent: item.content,
					  content: item.content_type === 0 ? escapeHtmlStr(item.content || '') : item.content,
					  tokenCount: item.token_count,
					  errorMsg: item.content ? '' : errorMsg,
					  checked: false,
					  favoriteLoading: false
          });
        }

        if (item.type === 'answer') {
          result.push({
					  id: item.message_id + '',
					  role: 'bot',
					  type: 'question',
					  contentType: item.content_type,
					  modelType: model_type,
					  status: 'ready',
					  createdAt: item.created_at,
					  replyMessage: this.getReplyMessageContent(item.reply_message),
					  sourceContent: item.content_type === 0 ? item.content : '',
					  content: this.makeAnswerContent(item.content, item.content_type),
					  tokenCount: item.token_count,
					  errorMsg: item.content ? '' : errorMsg,
					  checked: false,
					  favoriteLoading: false
          });
        }
      });

      return result;
    },

    makeAnswerContent(srcContent, type) {
    	if (type === 0) {
    		return marked(srcContent || '');
    	}

    	if (type === 1) {
    		return JSON.parse(srcContent);
    	}

    	if (type === 2) {
    		const srcData = JSON.parse(srcContent);
    		let result = {
    			...srcData,
    			actionButtons: [],
    		};

    		if (srcData.type === 'MidjournyPlusProxy') {
    			srcData.actionButtons.forEach((nameInfo) => {
	    			const actionButton = findActionInfoByValue(nameInfo);

	    			if (actionButton) {
	    				result.actionButtons.push({
	    					...actionButton,
	    					actionId: nameInfo.custom_id
	    				});
	    			}
	    		});
    		}

    		return result;
    	}

    	return '未知消息类型';
    },

		initMarkdownRender() {
      const renderer = new marked.Renderer();

      renderer.code = function (code, language) {
        const validLanguage = hljs.getLanguage(language) ? language : 'plaintext';
        const highlightedCode = hljs.highlight(validLanguage, code).value;

        return `
          <div class="custom-code-block">
            <div class="custom-code-block__header">
              <h4>${validLanguage}</h4>
              <span class="custom-code-block__copy" onclick="copyToClipboard(event)">复制</span>
            </div>
            <div>
               <pre><code class="hljs code-content language-${validLanguage}">${highlightedCode}</code></pre>
            </div>
          </div>
        `;
      };

      marked.setOptions({
        renderer: renderer,
        langPrefix: 'hljs language-',
        pedantic: false,
        gfm: true,
        breaks: false,
        sanitize: false,
        smartLists: true,
        smartypants: false,
        xhtml: false,
      });
      window.copyToClipboard = this.copyToClipboard.bind(this);
    },

    copyToClipboard(event) {
    	const block = closest(event.target, 'custom-code-block');
      const codeContent = getByClass(block, 'code-content')[0];
      const code = codeContent.textContent;
      const textarea = document.createElement('textarea');

      textarea.value = code;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      textarea.remove();

      this.setting.sound === '1' && successSound.play();

      this.$message({
        message: '复制成功',
        type: 'success',
        showClose: true,
        customClass: 'custom-message',
      });
    },

    // 接收回复
    async receiveAnswer(questionId, regenerte, chat_session_id) {
    	const messageId = uuid();
    	const botAnswer = {
        id: messageId,
			  role: 'bot',
			  type: 'answer',
			  modelType: this.chatSessionUseModel,
			  contentType: 0,
			  status: 'thinking',
			  createdAt: new Date().getTime(),
			  sourceContent: '',
			  checked: false,
			  favoriteLoading: false,
			  content: '',
			  tokenCount: 0,
			  errorMsg: ''
      };

      this.currentReceiveSessionInfo.chat_session_id = chat_session_id;
      this.currentReceiveSessionInfo.isNewAnswer = this.isNewChat;
      this.messageList.push(botAnswer);
      this.scrollToEnd();
      this.vueBotAnswer = botAnswer;
      
      let markSrcData = '';

      this.inputStatus = 'outputting';

      const currentSessionId = this.sessionId;

      try {
      	await checkLogin();
      }
      catch(err) {
      	this.$message({
	        message: err.message,
	        type: 'error',
	        showClose: true,
	        customClass: 'custom-message',
	      });
      };

      // 会话发生变化
    	if (currentSessionId !== this.sessionId) {
    		return;
    	}

      let path = `/api/receive_answer?id=${questionId}&bot_type=${this.chatSessionUseModel}&chat_session_id=${chat_session_id}&regenerte=0`;

      if (Boolean(regenerte)) {
      	path = `/api/receive_answer?id=${questionId}&bot_type=${this.chatSessionUseModel}&chat_session_id=${chat_session_id}&regenerte=1`;
      }

      this.source = new EventSource(path);
      this.source.onopen = () => {};
      this.source.onclose = () => {
        this.inputStatus = 'waitInput';
      }
      this.source.onerror = (err) => {
        botAnswer.errorMsg = '对不起，发生了一些错误，请重试。';
      	this.answerFail = true;
      	this.stopAnswer();
        this.scrollToEnd();
      };
    	this.source.onmessage = (event) => {
    		if (event.data === '[DONE]') {
          this.stopAnswer();
          return;
        }

        if (/message_id/.test(event.data)) {
          const { message_id } = JSON.parse(event.data);

          botAnswer.id = message_id;
          return;
        }

        if (/TOKEN_COUNT/.test(event.data)) {
        	const count = Number(event.data.match(/\d+/)[0]);

        	botAnswer.tokenCount = count;
        	return;
        }

        if (event.data === '[rechargeData]') {
        	botAnswer.errorMsg = '余额不足, 请购买积分，或开通会员';
          this.stopAnswer();
          return;
        }

        if (/^\[ERROR\]-/.test(event.data)) {
        	const errorMsg = event.data.replace(/^\[ERROR\]-/, '');

          botAnswer.errorMsg = errorMsg;
          this.stopAnswer();
          return;
        }
        
        // if (botAnswer.status !== 'insert') {
        // 	this.tapSoundId = tapSound.play();
        // }

        botAnswer.status = 'insert';
        decode(JSON.parse(event.data), (data) => {
        	try {
	          markSrcData += data;

	          const htmlString = marked(markSrcData);

	          botAnswer.sourceContent = markSrcData;
	          botAnswer.content = this.inserPointerClass(htmlString);
	          setTimeout(() => {
	          	this.autoScroll && this.scrollToEnd();
	          }, 10);
        	}
        	catch(err) {}
        });
    	};
    },

    newChatAnswerStop({chat_session_id, isNewAnswer}) {
    	if (!isNewAnswer) {
    		return;
    	}

    	eventBus.$emit('refreshChatSessionList');
			this.$store.dispatch('updateChatSessionId', chat_session_id);
			this.$router.push({
    		path: `/text/${chat_session_id}`
    	});
    	this.firstPageInitial = true;
    },

    stopAnswer() {
    	if (this.isTextChat()) {
    		this.stopAnswerEvent();
    	}
      
      if (this.isImageChat()) {
      	this.stopDrawImageTask();
      }

      this.newChatAnswerStop(this.currentReceiveSessionInfo);
    },

    // 文本模型
    isTextChat() {
    	return [0, 1, 50, 100].includes(this.chatSessionUseModel);
    },

    // 绘图模型
    isImageChat() {
    	return this.chatSessionUseModel === 2 || this.chatSessionUseModel === 3;
    },

    stopAnswerEvent() {
    	// if (this.tapSoundId) {
    	// 	tapSound.stop(this.tapSoundId);
    	// 	this.tapSoundId = null;
    	// }

    	if (this.source) {
    		this.source.close();

    		if (this.setting.sound === '1') {
    			receiveSound.play();
    		}
    		
    		this.source = null;
    	}

    	if (this.vueBotAnswer) {
    		this.vueBotAnswer.status = 'ready';
    	}
      
    	this.inputStatus = 'waitInput';
    },

    scrollToEnd() {
    	const scrollView = this.$el.querySelector('.text-chat__scroll-view');

    	setTimeout(() => {
    		scrollView.scrollTo(0, scrollView.scrollHeight);
    	}, 0);
    },

    reply(replyInfo) {
    	const { id, content } = replyInfo;

    	this.$refs.messageInput.setReplyInfo({
        id,
        content,
      });
      this.$refs.messageInput.focus();
    },

    deleteMessage(params) {
    	const { messageId } = params;

    	this.deleteMessageId = messageId;
    	this.delDialogVisible = true;
    },

    onChooseQuestion(opts) {
    	if (this.inputStatus !== 'waitInput') {
    		return;
    	}

    	this.$refs.messageInput.setMessage(opts.question);
    	this.onSubmitMessage({
    		message: opts.question,
    		replyMessage: '',
    		replyId: ''
    	});
    },

    async delOk() {
    	this.delLoading = true;

      try {
        const res = await deleteMessage({
          messageId: this.deleteMessageId
        });

        if (res.code !== 0) {
          this.delDialogVisible = false;
          this.delLoading = false;
          this.$message({
            message: '删除失败',
            type: 'error',
            showClose: true,
            customClass: 'custom-message',
          });
          return;
        }

        this.deleteMessageFromRenderList(this.deleteMessageId);
      }
      catch(err) {
        console.log(err);
        this.$message({
          message: '删除失败',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.delDialogVisible = false;
      this.delLoading = false;
    },

    deleteMessageFromRenderList(messageId) {
      for (let i = 0; i < this.messageList.length; i++) {
        if (this.messageList[i].id === messageId) {
          this.messageList.splice(i, 1);
          break;
        }
      }
    },

    scrollToMessage(messageId) {
    	const scrollView = this.$el.querySelector('.text-chat__scroll-view');

    	for (let i = 0; i < this.$children.length; i++) {
    		if (this.$children[i].$options.name !== 'Message' || this.$children[i].dataSource.id !== messageId) {
    			continue;
    		}

    		const firstMessagePos = getPos(this.$children[i].$el);
    		const scrollBodyPos = getPos(this.$el.querySelector('.text-chat__body'));

    		scrollView.scrollTo(0, firstMessagePos.top - scrollBodyPos.top - 66);
    		break;
    	}
    },

    pageTouchBottom() {
    	const scrollView = this.$el.querySelector('.text-chat__scroll-view');
    	const scrollBody = this.$el.querySelector('.text-chat__body');
    	const scrollViewHeight = parseFloat(getComputedStyle(scrollView).height);
    	const scrollBodyHeight = parseFloat(getComputedStyle(scrollBody).height);
    	const scrollTop = scrollView.scrollTop;
      
      return scrollBodyHeight - scrollViewHeight - 50 <= scrollTop;
    },

    inserPointerClass(htmlString) {
    	if (!htmlString) {
    		return htmlString;
    	}

    	const wraperDom = document.createElement('div');

    	wraperDom.innerHTML = htmlString;

			const lastTextNode = findLastTextNode(wraperDom);

			if (!lastTextNode) {
				return htmlString;
			}

			const parentNode = lastTextNode.parentNode;

			parentNode.classList.add('chat__answer-insert-animation');

			return wraperDom.innerHTML;
    },
	},

	mounted() {
		this.init();
	},

	components: {
		TextModelSwitch,
		MessageInput,
		QustionItems,
		Message
	}
}