import cookie from 'cookie-utils-js';
import { mapGetters } from 'vuex';
import { eventBus } from '@/utils/event';
import { sendMessage } from '@/api/chat';
import { escapeHtmlStr, sleep } from '@/utils/util';
import { sendSound } from '@/audio';
import { mj, chatGPT } from '@/config/model';

export const messageInputMixin = {
	data() {
		return {
			taskCanceller: {},
			ctxTipsDialogVisible: false
		}
	},

	computed: {
		...mapGetters(['chatSessionUseModel', 'setting']),
		messageInputDisabled() {
			if (this.isNewChat) {
				return false;
			}

			return !this.firstPageInitial;
		}
	},

	methods: {
		cancelCtxVisible() {
			this.ctxTipsDialogVisible = false;
			cookie.set('show_ctx_tips', '1', {
				expires: 7 * 24 * 60 * 60 * 1000
			});
		},

		goSetCtx() {
			this.cancelCtxVisible();
			eventBus.$emit('openSetting', {
				tabId: 'general'
			});
		},

		// 输入框提交消息
    async onSubmitMessage(messageInfo) {
    	// 不允许发送空消息
    	if (!messageInfo.message) {
    		this.$message({
	        message: '消息不能为空',
	        type: 'error',
	        showClose: true,
	        duration: 5000,
	        customClass: 'custom-message',
	      });
    		return;
    	}

    	const showCtxTips = cookie.get('show_ctx_tips');

    	if (this.setting.use_context === '1' && showCtxTips !== '1' && this.isTextChat()) {
    		this.ctxTipsDialogVisible = true;
    		return;
    	}

    	const cnTextRegex = /[\u3000-\u303f\u4e00-\u9fff]/;

    	if (this.chatSessionUseModel === 3 && cnTextRegex.test(messageInfo.message)) {
    		this.$message({
	        message: `${mj}不支持中文指令，建议先使用${chatGPT}把指令翻译为英语`,
	        type: 'error',
	        showClose: true,
	        customClass: 'custom-message',
	      });
    		return;
    	}

    	const currentSessionId = this.sessionId;

    	this.inputStatus = 'sending';

    	try {
    		// 发送消息时，会把当前使用的模型类型带过去，创建会话时需要
    		const { message_id, chat_session_id, pushData } = await this.sendMessage(messageInfo);

    		// 会话发生变化
	    	if (currentSessionId !== this.sessionId) {
	    		return;
	    	}

	    	this.setting.sound === '1' && sendSound.play();
	    	this.messageList.push(pushData);
	    	this.$refs.messageInput.reset();
	    	this.inputFocus();
	    	this.scrollToEnd();

	    	// 绘图模型
	    	if (this.chatSessionUseModel === 2 || this.chatSessionUseModel === 3) {
	    		this.receiveImageAnswer({
	    			questionId: message_id,
	    			chat_session_id,
	    			regenerte: false
	    		});
	    		return;
	    	}

	    	this.receiveAnswer(message_id, false, chat_session_id);
    	}
    	catch(err) {
    		if (err.code === 311) {
    			eventBus.$emit('openVIP');
    		}
    		this.inputStatus = 'waitInput';
    		this.inputFocus();
    		this.$message({
	        message: err.message,
	        type: 'error',
	        showClose: true,
	        customClass: 'custom-message',
	      });
    	}
    },

    inputFocus() {
    	setTimeout(() => {
    		this.$refs.messageInput && this.$refs.messageInput.focus();
    	}, 100);
    },

    // 发送消息
    async sendMessage(userMessage) {
    	const { message, replyMessage, replyId } = userMessage;
    	const messageSaveInfo = await sendMessage({
    		botType: this.chatSessionUseModel,
    		message,
    		replyId,
    		chat_session_id: this.chatSessionId || ''
    	});
    	const { chat_session_id, token_count, message_id, created_at } = messageSaveInfo;

		  return {
		  	message_id,
		  	chat_session_id,
		  	pushData: {
		  		id: message_id,
			    role: 'user',
			    type: 'question',
			    status: 'ready',
			    createdAt: created_at,
			    contentType: 0,
			    favoriteLoading: false,
			    checked: false,
			    replyMessage: {
			    	content: replyMessage,
			    	id: replyId
			    },
			    sourceContent: message,
			    content: escapeHtmlStr(message),
			    tokenCount: token_count,
			    errorMsg: ''
		  	}
		  };
    },
	}
};