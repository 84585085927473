import { chatGPT, mj, openai } from '@/config/model';

export default {
	name: 'Contact',

	data() {
		return {
			chatGPT,
			openai
		};
	}
};