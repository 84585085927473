import { mapGetters } from 'vuex';
import { saveSettings } from '@/api/userInfo';
import {
  contextIcon,
  dalleIcon,
  idIcon,
  notifyIcon,
  settingIcon,
  settingNotifyIcon,
} from '@/svg';
import { dalle3, gpt35, gpt40, omni } from '@/config/model';

export default {
	name: 'Setting',

	props: {
    openParams: {
      type: Object,
      default: () => {
        return {
          app: 'setting',
          path: 'general'
        }
      }
    }
  },

	data() {
		const pagePath = this.openParams.path.split('?')[0];
		
		return {
			gpt35,
			gpt40,
			omni,
			currentTab: pagePath,
      useContext: false,
      sound: true,
      dall3_size: '1024x1024',
      dall3_quality: 'standard',
      dall3_style: 'vivid',
      drawSizeOptions: [
      	{
      		value: '1024x1024',
      		label: '1024 x 1024'
      	},
      	{
      		value: '1792x1024',
      		label: '1792 x 1024'
      	},
      	{
      		value: '1024x1792',
      		label: '1024 x 1792'
      	}
      ],
      qualityOptions: [
      	{
      		value: 'standard',
      		label: '普通'
      	},
      	{
      		value: 'hd',
      		label: '高清'
      	},
      ],
      drawStyleOptions: [
      	{
      		value: 'vivid',
      		label: '生动'
      	},
      	{
      		value: 'natural',
      		label: '自然'
      	},
      ],
			menuList: [
				{
					id: 'general',
					name: '通用',
					icon: settingIcon
				},
				{
					id: 'draw',
					name: dalle3,
					icon: dalleIcon
				},
				{
					id: 'id_code',
					name: '唯一标识',
					icon: idIcon
				},
			]
		};
	},

	computed: {
		...mapGetters(['setting', 'openId']),
	},

	methods: {
		initInitialValue() {
			const { dalle3_size_type, dalle3_quality, dalle3_style, sound, use_context } = this.setting;

			this.useContext = use_context === '1';
			this.sound = sound === '1';
			this.dall3_size = dalle3_size_type;
			this.dall3_quality = dalle3_quality;
			this.dall3_style = dalle3_style;
		},

		chooseTab(item) {
			this.currentTab = item.id;
		},

		loginout() {
			this.$emit('onLoginout');
    },

		activeTab(tabId) {
			this.currentTab = tabId;
		},

		saveSetting() {
			this.saveSettingLoading = true;

			const params = {
				use_context: this.useContext ? '1' : '0',
				sound: this.sound ? '1' : '0',
				dalle3_size_type: this.dall3_size,
				dalle3_quality: this.dall3_quality,
				dalle3_style: this.dall3_style,
			};

			try {
				this.$store.dispatch('updateSetting', {
	      	...params
	      });
				saveSettings(params);
			}
			catch(err) {}
		}
	}
}