import { createImageTask, checkTaskStatus, queryMessageById, cancelTask } from '@/api/chat';
import { eventBus } from '@/utils/event';
import { uuid, sleep } from '@/utils/util';

export const imageAnswerMixin = {
	data() {
		return {
			currentImageTaskInfo: {
				task_id: '',
				answer_id: '',

				// 0-未创建任务 1-创建任务完成 2-绘制响应结束 3-取消任务
				status: 0,
				loadingTimer: null
			}
		};
	},

	methods: {
		async receiveImageAnswer(opts) {
			const { questionId, chat_session_id, regenerte } = opts;
			const messageId = uuid();
    	const botAnswer = {
        id: messageId,
			  role: 'bot',
			  type: 'answer',
			  modelType: this.chatSessionUseModel,
			  status: 'thinking',
			  percent: 0,
			  checked: false,
			  favoriteLoading: false,
			  createdAt: new Date().getTime(),
			  sourceContent: '',
			  content: {
			  	url: '',
			  	revised_prompt: ''
			  },
			  tokenCount: 0,
			  contentType: 1,
			  errorMsg: ''
      };

      this.vueBotAnswer = botAnswer;
      this.currentImageTaskInfo.status = 0;
      this.currentImageTaskInfo.answer_id = messageId;
      this.currentReceiveSessionInfo.chat_session_id = chat_session_id;
      this.currentReceiveSessionInfo.isNewAnswer = this.isNewChat;
      this.messageList.push(botAnswer);
      this.scrollToEnd();
      this.inputStatus = 'outputting';

      const currentSessionId = this.sessionId;

      try {
      	const { task_id, answer_id } = await createImageTask({
	      	chat_session_id,
	      	id: questionId,
	      	bot_type: this.chatSessionUseModel,
	      	regenerte: regenerte ? 1 : 0
	      });

	      // 会话发生变化
	    	if (currentSessionId !== this.sessionId) {
	    		return;
	    	}

	    	this.scrollToEnd();

      	// 保存当前正在绘制的图片任务id
      	this.currentImageTaskInfo.status = 1;
	      this.currentImageTaskInfo.task_id = task_id;
	      this.currentImageTaskInfo.answer_id = answer_id;
	      this.vueBotAnswer.id = answer_id;

	      if (this.currentImageTaskInfo.status === 3) {
	    		return;
	    	}

	      await sleep(500);
	      botAnswer.percent = 5;

	      // 轮训检测任务执行状态
      	const taskStatusInfo = await this.checkImageTaskStatus(task_id);

      	this.resetCurrentImageTaskInfo();

      	// 任务执行成功
      	if (taskStatusInfo.status === 2) {
      		this.currentImageTaskInfo.status = 2;
      		botAnswer.percent = 100;

      		await sleep(500);

      		// 绘制完成
      		const { content, content_type } = taskStatusInfo.answer_detail;

      		this.vueBotAnswer.contentType = content_type;
      		this.vueBotAnswer.content = this.makeAnswerContent(content, content_type);
      		this.imageDrawDone(this.currentReceiveSessionInfo);
      	}

      	// 任务执行失败
      	if (taskStatusInfo.status === 3) {
      		this.currentImageTaskInfo.status = 2;
      		this.vueBotAnswer.errorMsg = taskStatusInfo.remark;
      		this.imageDrawDone(this.currentReceiveSessionInfo);
      	}

      	// 任务被取消，把占位answer删除掉
      	if (taskStatusInfo.status === 4) {
      		this.currentImageTaskInfo.status = 2;
      	}

      	this.scrollToEnd();
      }
      catch(err) {
      	if (err && err.code === 311) {
      		this.vueBotAnswer.errorMsg = '余额不足, 请购买积分';
      	}

      	if (err && err.code === 456) {
      		this.vueBotAnswer.errorMsg = err.message;
      	}
      	
      	this.resetCurrentImageTaskInfo();
      	this.imageDrawDone(this.currentReceiveSessionInfo);
      	this.scrollToEnd();
      }
		},

		resetCurrentImageTaskInfo() {
			this.currentImageTaskInfo.task_id = '';
			this.currentImageTaskInfo.answer_id = '';
			this.currentImageTaskInfo.status = 0;
			this.currentImageTaskInfo.loadingTimer = null;
		},

		updateImageLoading() {
			if (this.vueBotAnswer.percent >= 90) {
    		this.vueBotAnswer.percent = 90;
    		return;
    	}

    	this.vueBotAnswer.percent += 1;
		},

		imageDrawDone({chat_session_id, isNewAnswer}) {
			this.vueBotAnswer.status = 'ready';
      this.inputStatus = 'waitInput';

			if (!isNewAnswer) {
    		return;
    	}

    	eventBus.$emit('refreshChatSessionList');
			this.$store.dispatch('updateChatSessionId', chat_session_id);
			this.$router.push({
    		path: `/text/${chat_session_id}`
    	});
    	this.firstPageInitial = true;
		},

		deleteMessageById(messageId) {
			for (let i = 0; i < this.messageList.length; i++) {
				if (this.messageList[i].id === messageId) {
					this.messageList.splice(i, 1);
					break;
				}
			}
		},

		// 停止图片绘制任务
		async stopDrawImageTask() {
			try {
				if (this.currentImageTaskInfo.task_id) {
					cancelTask({
						task_id: this.currentImageTaskInfo.task_id
					});
				}
				
				this.currentImageTaskInfo.status = 3;
				this.inputStatus = 'waitInput';

				if (this.currentImageTaskInfo.answer_id) {
					this.deleteMessageById(this.currentImageTaskInfo.answer_id);
				}
				
				this.imageDrawDone(this.currentReceiveSessionInfo);
			}
			catch(err) {}
		},

		// 轮训检测任务状态
		async checkImageTaskStatus(taskId) {
			try {
				if (this.currentImageTaskInfo.status === 3) {
	    		return Promise.reject();
	    	}

				const { status, remark, progress, answer_detail } = await checkTaskStatus({
					task_id: taskId
				});

				if (status === 1) {
					if (progress >= this.vueBotAnswer.percent) {
						this.vueBotAnswer.percent = progress;
					}
				}

				if (status !== 2 && status !== 3 && status !== 4) {
					await sleep(1000);
					return this.checkImageTaskStatus(taskId);
				}

				return {
					status,
					remark,
					progress,
					answer_detail
				};
			}
			catch(err) {
				return {
					status: 3,
					remark: '网络错误, 请重试'
				};
			}
		}
	}
}

