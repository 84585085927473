import { network } from "./network";

export async function createOrder(params) {
    const {amount, payType, selectedCouponId} = params;
    const res = await network({
      url: '/api/create_order',
      method: 'post',
      params: {
        amount,
        payType,
        coupon_id: selectedCouponId
      }
    });

    return res;
}

export async function getOrderInfo(opts) {
  const res = await network({
    url: '/api/query_order',
    method: 'get',
    params: {
      page: opts.pageNumber,
      page_size: opts.page_size
    }
  });

  return res;
}

export async function getOrderStatus(opts) {
  const res = await network({
    url: '/api/order_status',
    method: 'post',
    params: {
      order_number: opts.order_number,
    }
  });

  if (res.code !== 0) {
    return Promise.reject(res);
  }

  return res;
}