export default {
	props: {
		content: {
			type: String,
			default: ''
		}
	},

	methods: {
		close() {
			this.$emit('onClose');
		}
	}
};