// 白银会员
import { gpt35, gpt40, mj, dalle3, omni, wenXin } from '@/config/model';
import {
	gpt35InputBase,
	gpt35OutputBase,
	gpt40InputBase,
	gpt40OutputBase,
	omniInputBase,
	omniOutputBase,
	mjBase,
	dalleBase
} from './base';

const beishu = 4;
const mgBeishu = 3;

export const v0Plan = {
	type: 0,
	credit: 0,
	rmb: 0,
	gift: [
		// {
		// 	name: wenXin,
		// 	count: null
		// },
	],
	streamBilling: [
		{
			name: gpt35,
			details: [
				{
					name: `输入: ${gpt35InputBase * beishu}积分/token`
				},
				{
					name: `输出: ${gpt35OutputBase * beishu}积分/token`
				}
			],
		},
		{
			name: gpt40,
			details: [
				{
					name: `输入: ${gpt40InputBase * beishu}积分/token`
				},
				{
					name: `输出: ${gpt40OutputBase * beishu}积分/token`
				}
			]
		},
		{
			name: omni,
			details: [
				{
					name: `输入: ${omniInputBase * beishu}积分/token`
				},
				{
					name: `输出: ${omniOutputBase * beishu}积分/token`
				}
			]
		},
		{
			name: mj,
			details: [
				{
					name: `${mjBase * mgBeishu}万积分/张`
				},
			]
		},
		{
			name: dalle3,
			details: [
				{
					name: `普通-1024x1024: ${dalleBase * mgBeishu}万积分/张`
				},
				{
					name: `普通-1024x1792: ${dalleBase * mgBeishu * 2}万积分/张`
				},
				{
					name: `普通-1792x1024: ${dalleBase * mgBeishu * 2}万积分/张`
				},
				{
					name: `高清-1024x1792: ${dalleBase * mgBeishu * 2}万积分/张`
				},
				{
					name: `高清-1024x1024: ${dalleBase * mgBeishu * 3}万积分/张`
				},
				{
					name: `高清-1792x1024: ${dalleBase * mgBeishu * 3}万积分/张`
				},
			]
		}
	]
}