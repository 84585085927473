import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
	isLogin: false,
	userName: '',
	nickname: '',
	photo: '',
	userType: '',
	openId: '',
	vipLevel: 0,
	vipRulesInfo: null,
	vipExpireDate: null,
	lemonBeanCount: 0,
	setting: {}
}

export default {
	state,
	getters,
	actions,
	mutations
};