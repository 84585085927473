import { mapGetters } from 'vuex';
import { arrowUp } from '@/svg';

export default {
	name: 'QustionItems',

	data() {
		return {
			arrowUp,
		};
	},

	computed: {
		...mapGetters(['chatSessionUseModel']),

		questions() {
			if (this.chatSessionUseModel === 2) {
				return [
					{
						id: 'bird',
						title: '清晨露珠',
						subTitle: '清晨露珠落在充满活力的绿叶上的特写照片',
						question: `Close-up photograph of morning dew drops on a vibrant green leaf, highlighting the intricate patterns and reflections.`
					},

					{
						id: 'hudie',
						title: '蝴蝶',
						subTitle: '画一只气泡蝴蝶',
						question: 'Create an intricate and refined bubble, delicately shaped like a butterfly. The bubble butterfly should display a mesmerizing mix of purple and white colors, arranged in a thoughtful pattern that could be used for color blindness testing. This beautifully detailed bubble is set against a pristine white background, which intensifies its vibrant colors and emphasizes its magnificence.'
					},

					{
						id: 'party',
						title: '产品包装设计',
						subTitle: '为名为“葡萄园珍宝”的葡萄酒设计一个外观吸引人的产品包装',
						question: `Create a visually stunning design for a wine packaging of a brand named 'Vineyard Treasure'. The design should embody elements of sophistication and luxury, while also showcasing the rich history and culture associated with vineyards. Include intricate details such as grapevines winding around the bottle, a gold-embossed name plaque, along with vintage textures and colors such as deep purples and burgundy. The overall design should exude an aura of exclusivity, intending to appeal to a sophisticated audience of wine connoisseurs.`
					},

					{
						id: 'code',
						title: '奇幻世界',
						subTitle: '制作一个充满浮岛的奇幻世界',
						question: `Imagine a rich, colorful fantasy universe above a limitless blue ocean where an array of floating islands ascend into the endless sky, each harboring its unique identity and home to mythical creatures of myriad shapes and sizes. One island is teeming with graceful, winged serpents, another hums with the gentle whispers of tree spirits, and on another, the roars of beasts that have the power to exhale fire can be heard. This realm embodies different mythologies brought to life. The word 'Fantasy' is prominently carved, resting within a magical bubble that perfectly blends with the audacious surroundings.`
					},
				];
			}

			if (this.chatSessionUseModel === 3) {
				return [
					{
						id: 'beautiful',
						title: '室内设计',
						subTitle: '安藤忠雄的设计风格',
						question: 'Living room， Tadao Ando design style，Bauhaus style furniture， no main lighting， naturallight， minimalist design， ultra high definition， 8k --ar 16:9 --q 2 --s 750 --v 6'
					},
					{
						id: 'beiying',
						title: '女英雄',
						subTitle: '冷酷的目光，苍白的肤色',
						question: 'a chinese ancient heroine in the snow,cold gaze,pale skin tone,extreme close-up,wearing black the clothing of the northern barbarians of ancient china,highly detailed dramatic lighting in the style of albrecht durer and gustave dore, influenced by william blake,,in clothes --s 100 --v 6'
					},
					{
						id: 'tone',
						title: '瓶子里的水果',
						subTitle: '有一个装满水果和蔬菜的玻璃容器，其中有仙子水果',
						question: `There is a glass container filled with fruits and vegetables, which contains fairy fruits. Octane rendering, organic 8k art photography, 3D surreal rendering, inspired by Mike Winkelmann and Greg Rutkowski. Octane rendering, ultra realistic and detailed rendering, ultra realistic 3D illustrations, food commercial 4k, ultra realistic digital art. --s 100 --iw 1.25 --version 6`
					},
					{
						id: 'shuiguo',
						title: '猕猴桃切片',
						subTitle: '高分辨率特写照片',
						question: `A high-resolution close-up photograph of kiwi slices showcases their vibrant green color and intricate patterns. The focus is on the texture and details of each slice, with water droplets glistening in perfect symmetry. The background of the entire kiwi adds depth to the composition, creating an attractive wallpaper-like environment for mobile phones. --c 11 --s 114 --version 6`
					}
				];
			}

			return [
				{
					id: 'logic-test',
					title: '逻辑检测',
					subTitle: '测试3.5与4.0的区别',
					question: '周树人为什么暴打鲁迅。'
				},

				{
					id: 'travel',
					title: '计划一次旅行',
					subTitle: '三天内游览成都最好的地方',
					question: '我希望三天内游览成都最好的地方，帮我指定一个旅行计划吧。'
				},

				{
					id: 'trans',
					title: '翻译',
					subTitle: '把中文翻译为英语',
					question: `把引号内的中文翻译为英语，"但愿人长久，千里共婵娟"`
				},

				{
					id: 'code',
					title: '编写代码',
					subTitle: '如何使用javascript发起一个HTTP请求',
					question: `如何使用javascript发起一个HTTP请求?`
				},
			];
		}
	},

	methods: {
		chooseQuestion(questionInfo) {
			this.$emit('onChooseQuestion', {
				question: questionInfo.question,
				model: this.chatSessionUseModel
			});
		}
	}
}