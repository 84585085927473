var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chat-page"},[_vm._m(0),_c('div',{staticClass:"chat-page__body"},[_c('div',{staticClass:"chat-page__left-area",class:[{'chat-page__left-area--hide': !_vm.showSlider}]},[_c('div',{staticClass:"chat-page__left-area-inner"},[_c('NewChat',{on:{"openNewChat":_vm.openNewChat}}),_c('ChatList')],1)]),_c('div',{staticClass:"chat-page__right-area"},[_c('router-view'),_c('el-tooltip',{attrs:{"popper-class":"slider-bar-tips","content":_vm.showSlider ? '关闭侧边栏' : '打开侧边栏',"placement":"right"}},[_c('div',{class:[
            'chat-page__toggle-btn-box',
            {
              'chat-page__toggle-btn-box--show-slider-active': _vm.showSliderActive,
              'chat-page__toggle-btn-box--hide-slider': !_vm.showSlider
            }
          ],on:{"click":_vm.toggleSlider,"mouseleave":_vm.mouseleaveToggleBtn,"mouseenter":_vm.mouseenterToggleBtn}},[_c('div',{staticClass:"chat-page__toggle-btn"},[_c('div',{staticClass:"chat-page__toggle-btn-arrow-top"}),_c('div',{staticClass:"chat-page__toggle-btn-arrow-bottom"})])])])],1)]),(_vm.showFeedback)?_c('div',{staticClass:"use-feedback"},[_c('h3',{staticClass:"use-feedback__title"},[_vm._v("评分有奖")]),_c('div',{staticClass:"use-feedback__body"},[_c('p',{staticClass:"use-feedback-tips"},[_vm._v("使用这么久了，请给我们打个分吧！")]),_c('div',{staticClass:"flex-center use-feedback__starts"},[_c('el-rate',{attrs:{"colors":['#10a37f', '#10a37f', '#10a37f']},model:{value:(_vm.feedbackStartValue),callback:function ($$v) {_vm.feedbackStartValue=$$v},expression:"feedbackStartValue"}})],1),_c('div',{staticClass:"use-feedback__input"},[_c('el-input',{attrs:{"type":"textarea","rows":4,"resize":"none","placeholder":"我们期待您的建议(非必填)"},model:{value:(_vm.feedbackTextValue),callback:function ($$v) {_vm.feedbackTextValue=$$v},expression:"feedbackTextValue"}})],1)]),_c('div',{staticClass:"use-feedback__footer"},[_c('el-button',{attrs:{"type":"primary","loading":_vm.feedbackLoading},on:{"click":_vm.submitFeedback}},[_vm._v("提交评价")]),_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":_vm.nextTimeFeedback}},[_vm._v("以后再说")])],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chat-page__rain"},[_c('div',{staticClass:"chat-page__rain-body",attrs:{"id":"chat-page__rain"}},[_c('div',{staticClass:"chat-page__rain-inner"})])])
}]

export { render, staticRenderFns }