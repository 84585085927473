import { Loading } from 'element-ui';
import { getAllCoupons } from '@/api/coupon';
import Coupon from '@/components/Coupon/Coupon.vue';
import { toFixedNumber, isMobile } from '@/utils/util';
import { createOrder } from "@/api/order";

export default {
    components: {
        Coupon
    },
    data() {
        return {
          couponList: [],
          payList: [
              {
                  rmb: 1,
                  breanCount: '10万',
                  free: '0',
                  id: '1',
              },
              // 1折
              {
                  rmb: 5,
                  breanCount: '50万',
                  free: '0',
                  id: '5',
              },

              // 9折
              {
                  rmb: 10,
                  breanCount: '100万',
                  free: '10万',
                  id: '10',
              },

              // 8.6折
              {
                  rmb: 20,
                  breanCount: '200万',
                  free: '30万',
                  id: '20',
              },

              // 8.3折
              {
                  rmb: 50,
                  breanCount: '500万',
                  free: '100万',
                  id: '50',
              },

              // 8折
              {
                  rmb: 100,
                  breanCount: '1000万',
                  free: '250万',
                  id: '100',
              },

              // 7.7折
              {
                  rmb: 200,
                  breanCount: '2000万',
                  free: '600万',
                  id: '200',
              },

              // 7.4折
              {
                  rmb: 500,
                  breanCount: '5000万',
                  free: '1750万',
                  id: '500',
              },

              // 7.1折
              {
                  rmb: 1000,
                  breanCount: '1亿',
                  free: '4000万',
                  id: '1000',
              },
          ],
          payMethods: [
              {
                  id: 'wxpay',
                  name: '微信支付'
              }
          ],
          selectedRMB: 5,
          selectedCouponId: null,
          payMethod: 'wxpay',
          loading: false,
          couponDialogVisible: false,
          couponErrorMessage: ''
        }
    },

    computed: {
      moneyResult() {
        const selectedCoupon = this.couponList.filter((coupon) => {
          return coupon.user_coupon_id === this.selectedCouponId
        });

        if (!selectedCoupon[0]) {
          return this.selectedRMB;
        }

        const { discount } = selectedCoupon[0];

        return toFixedNumber(this.selectedRMB * discount, 2);
      },

      subtractionRMB() {
        return toFixedNumber(this.selectedRMB - this.moneyResult, 2);
      }
    },

    methods: {
        init() {
            this.renderMyCoupon();
        },

        async renderMyCoupon() {
          const loading = Loading.service({
            fullscreen: true,
            customClass: 'chat-loading',
            background: 'rgba(35, 35, 35, 0.7)'
          });

          try {
            const list = await getAllCoupons();

            // 把没使用过的优惠券筛选出来，但有可能存在过期的优惠券，这个需要在提交订单的时候，在服务端做校验
            const filterList = list.filter((item) => {
              return item.status === 0 && item.in_progress;
            });

            if (filterList.length) {
              this.selectedCouponId = filterList[0].user_coupon_id;
            }
            this.couponList = filterList;
          }
          catch(err) {
            console.log(err);
          }
          loading.close();
        },

        selectMoney(item) {
            this.selectedRMB = item.rmb;
        },

        selectCoupon(coupon) {
          this.selectedCouponId = coupon.user_coupon_id;
        },

        payContinue() {
          this.selectedCouponId = null;
          this.couponDialogVisible = false;
          this.pay();
        },

        async pay() {
            if (this.loading) {
                return;
            }

            if (isMobile()) {
              this.$message({
                message: '请使用PC端支付',
                type: 'error',
                customClass: 'custom-message',
              });
              return;
            }

            this.loading = true;

            const params = {
                payType: this.payMethod,
                amount: this.selectedRMB,
                selectedCouponId: this.selectedCouponId
            };

            try {
                let res = await createOrder(params);

                if (res.code == 0){
                  const loading = Loading.service({
                      fullscreen: true,
                      body: true,
                      text: '支付跳转中，请稍等...',
                      customClass: 'chat-loading',
                      background: 'rgba(35, 35, 35, 0.7)'
                  });

                  setTimeout(() => {
                      window.location = res.data.link;
                  }, 500);
                } else if (res.code === 802) {
                  // 优惠券错误
                  this.couponDialogVisible = true;
                  this.couponErrorMessage = res.message;
                } else {
                  this.$message({
                    message: '订单创建失败: ' + res.message,
                    type: 'error',
                    customClass: 'custom-message',
                  });
                }
            }
            catch(err) {
                this.$message({
                    message: '订单创建失败',
                    type: 'error',
                    customClass: 'custom-message',
                });
            }

            this.loading = false;
        }
    },

    mounted() {
        // if(localStorage.show_log === '1') {
        //     this.payList.unshift({
        //         rmb: 1,
        //         breanCount: '10万',
        //         free: '0',
        //         id: '1',
        //     });
        // }
        this.init();
    }
}
