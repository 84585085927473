const mutations = {
	['UPDATE_USER_INFO'] (state, userInfo) {
		state.isLogin = userInfo.isLogin || false;
		state.userName = userInfo.userName || '';
		state.userType = userInfo.userType || '';
		state.openId = userInfo.openId || '';
		state.nickname = userInfo.nickname || '';
		state.photo = userInfo.photo || '';
		state.setting = userInfo.setting || {};
		state.lemonBeanCount = userInfo.lemonBeanCount || 0;
		state.vipLevel = userInfo.vipLevel || 0;
		state.vipRulesInfo = userInfo.vipRulesInfo || null;
		state.vipExpireDate = userInfo.vipExpireDate;
	},

	['UPDATE_SETTING'] (state, payload) {
		state.setting = payload;
	},

	['UPDATE_PLAN'] (state, payload) {
		state.vipLevel = payload.type;
		state.vipExpireDate = payload.vipExpireDate;
	},

	['UPDATE_LEMON_BEAN_COUNT'] (state, payload) {
		const { lemonBeanCount } = payload;
		
		state.lemonBeanCount = lemonBeanCount;
	}
};

export default mutations;