import { planIcon } from '@/svg';

export default {
	name: 'Plan',

	data() {
		return {
			planIcon
		}
	}
}