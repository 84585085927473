export function isChatSessionPath(path) {
	return /^\/text\/\d+/.test(path);
}

export function isEmptyChatSessionPath(path) {
	return /^\/text/.test(path);
}

export function findLastTextNode(node) {
  let lastTextNode = null;

  // 递归函数，用于深度优先遍历DOM树
  function traverseNodes(node) {
    // 确保是遍历的元素或文本节点
    if (node.nodeType === Node.ELEMENT_NODE || node.nodeType === Node.TEXT_NODE) {
      // 如果节点是文本节点且不只包含空白，则更新lastTextNode
      if (node.nodeType === Node.TEXT_NODE && !/^\s*$/.test(node.nodeValue)) {
        lastTextNode = node;
      }

      // 遍历子节点
      const children = node.childNodes;
      for (let i = 0; i < children.length; i++) {
        traverseNodes(children[i]);
      }
    }
  }

  traverseNodes(node);
  
  return lastTextNode;
}

export function closest(node, className) {
  let current = node;

  while(current && current.classList && !current.classList.contains(className)) {
    current = current.parentNode;
  }

  if (current === document) {
    return null;
  }

  return current;
}

export function getPos(obj){
  var l=0;
  var t=0;
  while(obj){
    l+=obj.offsetLeft;
    t+=obj.offsetTop;
    obj=obj.offsetParent; 
  }
  return {left:l, top: t};
}