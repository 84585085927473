import { login, getTicket, checkLoginStatus, canLogin } from '@/api/login';
import { siteThemeMixin } from '@/mixins/theme';
import { sleep, queryUrl } from '@/utils/util';

export default {
  name: 'Login',

  mixins: [siteThemeMixin],

  data() {
    return {
      userName: '',
      password: '',
      code: '',
      loginErrorMsg: '',
      loading: false,
      imgCodeUrl: '/api/code?1',
      loginQRCode: '',
      loginSuccess: false,
      qrcodeTimeout: false,
      appShow: true,
      tagMap: {},
    };
  },

  methods: {
    init() {
      this.renderQRCode();
      this.playVideo();
    },

    playVideo() {
      // videojs('chat-login__video-main', {
      //   autoplay: true,
      //   controls: false,
      //   loop: true,
      //   poster: 'https://static.next-ai.top/chat-static-source/poster.jpg'
      // });
    },

    async refreshCode() {
      this.qrcodeTimeout = false;
      await sleep(10);
      this.renderQRCode();
    },

    async renderQRCode() {
      const self = this;
      this.loginQRCode = '';
      this.loginSuccess = false;
      const loading = this.$loading({
        target: '#chat-login__qrcode-img',
        customClass: 'login-loading'
      });

      const { invite_code } = queryUrl(window.location.href);
      const res = await getTicket({
        invite_code
      });

      if (res.code !== 0) {
        return;
      }

      const { ticket, login_tag } = res.data;
      const codeurl = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${encodeURIComponent(ticket)}`;
      const img = new Image();

      img.onload = function() {
        self.loginQRCode = codeurl;
        loading.close();
        self.tagMap[login_tag] = true;
        self.checkLoginStatus(login_tag);
      }
      img.src = codeurl;
      setTimeout(() => {
        this.qrcodeTimeout = true;
        this.tagMap[login_tag] = false;
      }, 30000);
    },

    async checkLoginStatus(login_tag, redirect_url) {
      if (!this.tagMap[login_tag]) {
        return;
      }
      const res = await checkLoginStatus({
        login_tag,
      });

      if (res.code !== 0) {
        await sleep(500);
        this.checkLoginStatus(login_tag);
      }
      else {
        this.loginSuccess = true;
        this.$message({
          message: '扫码成功，登录中',
          type: 'success',
          customClass: 'custom-message',
        });
        await this.$store.dispatch('updateUserInfo');
        this.$router.push({
          path: '/'
        });
      }
    }
  },

  mounted() {
    this.init();
  }
}