import { network } from './network';

export async function redPacketStatus(params) {
	const { code } = params;
	const res = await network({
		url: '/api/red_packet_status',
		method: 'post',
		params: {
			code
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}