import { mapGetters } from 'vuex';
import { queryChatList, delChatSession, updateChatSessionName } from '@/api/chat';
import { moreIcon, renameIcon, delIcon, sendLoadingIcon } from '@/svg';
import { eventBus } from '@/utils/event';
import { delSound, payFailSound } from '@/audio';

export default {
	data() {
		return {
			moreIcon,
			renameIcon,
			sendLoadingIcon,
			delIcon,
			chatSrcList: [],
			chatList: [],
			loading: false,
			delDialogVisible: false,
			willDelChatName: '',
			delLoading: false,
			delItem: null
		};
	},

	directives: {
		focus: {
			// 指令的定义
	    inserted: function (el) {
	      el.focus();
	    }
		}
	},

	created() {
		this.getChatList();
		this.bindRefreshEvent();
	},

	computed: {
		...mapGetters(['chatSessionId', 'setting'])
	},

	methods: {
		bindRefreshEvent() {
			eventBus.$off('refreshChatSessionList');
			eventBus.$on('refreshChatSessionList', async () => {
				const chatSessionSrcList = await queryChatList();

				this.chatSrcList = chatSessionSrcList;
				this.chatList = this.makeRenderList(chatSessionSrcList);
				this.$refs.chatListView && this.$refs.chatListView.scrollTo(0, 0);
			});
		},

		async getChatList() {
			this.loading = true;

			const chatSessionSrcList = await queryChatList();

			this.chatSrcList = chatSessionSrcList;
			this.chatList = this.makeRenderList(chatSessionSrcList);
			this.loading = false;
			this.updateSessionModelType();
		},

		updateSessionModelType() {
			for (let i = 0; i < this.chatList.length; i++) {
				if (this.chatList[i].chat_session_id === this.chatSessionId) {
					this.$store.dispatch('updateChatSessionUseModel', this.chatList[i].model_type);
					break;
				}
			}	
		},

		// 删除会话
		delChatSession(item) {
			this.delDialogVisible = true;
			item.showOperatePanel = false;
			this.willDelChatName = item.chat_session_name;
			this.delItem = item;
		},

		async delChatSessionOk() {
			this.delLoading = true;

			try {
				await delChatSession({
					chat_session_id: this.delItem.chat_session_id
				});

				this.$message({
          message: '删除成功',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });

				// 如果删除的正好是当前选中的，需要自动切换到临近会话
        if (this.delItem.chat_session_id === this.chatSessionId) {
        	this.switchToNextSession();
        }

        this.deleteFromChatList(this.delItem.chat_session_id);

        if (this.setting.sound === '1') {
        	delSound.play();
        }
			}
			catch(err) {
				this.$message({
          message: '删除失败',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });

        if (this.setting.sound === '1') {
        	payFailSound.play();
        }
			}
			this.delLoading = false;
			this.delDialogVisible = false;
		},

		switchToNextSession() {
			const theNextSession = this.findNextSessionId(this.delItem.chat_session_id);

			this.checkChatSession(theNextSession);
		},

		findNextSessionId(id) {
			if (this.chatSrcList.length <= 1) {
				return '';
			}

			for(let i = 0; i < this.chatSrcList.length; i++) {
				if (this.chatSrcList[i].chat_session_id !== id) {
					continue;
				}

				if (i === this.chatSrcList.length - 1) {
					return this.chatSrcList[this.chatSrcList.length - 2];
				}

				return this.chatSrcList[i + 1];
			}

			return null;
		},

		deleteFromChatList(chatId) {
			for (let i = 0; i < this.chatList.length; i++) {
				if (this.chatList[i].chat_session_id === chatId) {
					this.chatList.splice(i, 1);
					this.chatSrcList.splice(i, 1);
					break;
				}
			}
		},

		showEditInput(item) {
			item.edit = true;
			item.showOperatePanel = false;
		},

		nameEditHanlder(item) {
			item.edit = false;
			this.updateChatSessionName(item);
		},

		async updateChatSessionName(item) {
			const { chat_session_id, chat_session_name } = item;
			const oldData = this.findChatSrcData(item.chat_session_id);

			// 标题未发生变化
			if (oldData.chat_session_name === chat_session_name) {
				return;
			}

			if (!chat_session_name) {
				this.$message({
          message: '会话标题不能为空',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
				this.updateBack(item);
				return;
			}

			try {
				await updateChatSessionName({
					chat_session_id,
					chat_session_name
				});
				this.updateSrcData(item);
				this.$message({
          message: '重命名成功',
          type: 'success',
          showClose: true,
          customClass: 'custom-message',
        });
			}
			catch(err) {
				this.$message({
          message: err.message,
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
        this.updateBack(item);
			}
		},

		checkChatSession({chat_session_id, model_type, edit}) {
			if (edit) {
				return;
			}

			eventBus.$emit('sessionChangeForceStop');
			this.$store.dispatch('updateChatSessionUseModel', model_type);
			this.$store.dispatch('updateChatSessionId', chat_session_id);
			this.$router.push({
    		path: Boolean(chat_session_id) ? `/text/${chat_session_id}` : '/text'
    	});

    	setTimeout(() => {
    		eventBus.$emit('initChatSessionMessageList');
    	}, 0);
		},

		updateSrcData(item) {
			const oldData = this.findChatSrcData(item.chat_session_id);

			oldData.chat_session_name = item.chat_session_name;
		},

		updateBack(item) {
			const { chat_session_name: oldName } = this.findChatSrcData(item.chat_session_id);

			item.chat_session_name = oldName;
		},

		findChatSrcData(id) {
			for (let i = 0; i < this.chatSrcList.length; i++) {
				if (this.chatSrcList[i].chat_session_id === id) {
					return this.chatSrcList[i];
				}
			}

			return null;
		},

		makeRenderList(chatSessionSrcList) {
			return chatSessionSrcList.map((item) => {
				return {
					...item,
					showOperatePanel: false,
					edit: false
				};
			});
		}
	}
}