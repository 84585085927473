const dayjs = require('dayjs');
import { getOrderInfo } from '@/api/order';
import { pointsIcon, shoppingCarIcon, creditRuleIcon, giftIcon, sendLoadingIcon } from '@/svg';

export default {
	name: 'OrderList',

  props: {
    openParams: {
      type: Object,
      default: () => {
        return {
          app: 'order',
          path: ''
        }
      }
    }
  },

	data() {
		return {
			sendLoadingIcon,
			loading: false,
			pageSize: 5,
			total: 1,
			currentPage: 1,
			orderList: []
		}
	},

	methods: {
    transStatus(status) {
      if (status === 1) {
        return '待支付';
      }

      if (status === 3) {
        return '已取消';
      }

      if (status === 2) {
        return '已完成';
      }

      return '未知状态';
	  },

	  initFirstPage() {
    	this.total = 1;
    	this.loadPage(1);
    },

	  transTime(time) {
      // 时间戳转时间字符串
      return dayjs(time).locale('zh-cn').format('YYYY-MM-DD HH:mm');
    },

    initFirstPage() {
    	this.total = 1;
    	this.loadPage(1);
    },

    async loadPage(pageNumber) {
    	this.loading = true;

    	try {
        const res = await getOrderInfo({
        	pageNumber,
        	page_size: this.pageSize
        });
        
        if (res.code == 0) {
          this.orderList = res.data.order_list;
          this.total = res.data.total_count;
        } else {
          this.$message({
            message: '查询失败',
            type: 'error',
            showClose: true,
            customClass: 'custom-message',
          });
        }
      }
      catch(err) {
        this.$message({
          message: '查询失败',
          type: 'error',
          showClose: true,
          customClass: 'custom-message',
        });
      }

      this.loading = false;
    },

    pageChange() {
    	this.loadPage(this.currentPage);
    },

	  getChangeDetail(row) {
      if (row.compute_type === 1) {
        return `+${row.count}`;
      }

      if (row.compute_type === 2) {
        return `-${row.count}`;
      }

      return '';
    },
	}
}