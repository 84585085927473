import { sleep } from '@/utils/util';
import { getUserInfo } from '@/api/userInfo';

const actions = {
	updateChatSessionId({ commit, state }, payload) {
		commit('UPDATE_CHAT_SESSION_ID', {
			chatSessionId: payload
		});
	},

	updateChatSessionUseModel({ commit, state }, payload) {
		commit('UPDATE_CHAT_SESSION_MODEL', {
			modelType: payload
		});
	},

	updateUnReadMessages({ commit, state }, payload) {
		commit('UPDATE_UN_READ_MESSAGES', {
			messages: payload
		});
	},

	readMessages({ commit, state }, payload) {
		const { app_id, path } = payload;

		commit('READ_MESSAGE', {
			app_id,
			path
		});
	},

	updateCurReceiveSessionId({ commit, state }, payload) {
		commit('UPDATE_CUR_RECEIVE_SESSION_ID', {
			chatSessionId: payload
		});
	}
}

export default actions;
