export const gpt35InputBase = 2;
export const gpt35OutputBase = 3;

export const gpt40InputBase = 8;
export const gpt40OutputBase = 22;

export const omniInputBase = 4;
export const omniOutputBase = 11;

export const mjBase = 3;
export const dalleBase = 3;