import { trrigerImageAction } from '@/api/chat';
import { uuid, sleep } from '@/utils/util';

export const imageActionMixin = {
	methods: {
		async onAction(opts, messageInfo) {
			const { actionName, actionId } = opts;
			const messageId = messageInfo.id;

			if (this.inputStatus === 'outputting') {
				this.$message({
	        message: '上一个任务进行中，请稍后',
	        type: 'error',
	        showClose: true,
	        customClass: 'custom-message',
	      });
	      return;
			}

			this.trrigerImageAction({
				actionName,
				actionId,
				questionId: messageId,
				chat_session_id: this.chatSessionId,
			});
		},

		async trrigerImageAction(opts) {
			const { questionId, chat_session_id, actionName, actionId } = opts;
			const messageId = uuid();
    	const botAnswer = {
        id: messageId,
			  role: 'bot',
			  type: 'answer',
			  modelType: this.chatSessionUseModel,
			  status: 'thinking',
			  percent: 0,
			  checked: false,
			  favoriteLoading: false,
			  createdAt: new Date().getTime(),
			  sourceContent: '',
			  content: {
			  	url: '',
			  	revised_prompt: ''
			  },
			  tokenCount: 0,
			  contentType: 2,
			  errorMsg: ''
      };

      this.vueBotAnswer = botAnswer;
      this.currentImageTaskInfo.status = 0;
      this.currentImageTaskInfo.answer_id = messageId;
      this.currentReceiveSessionInfo.chat_session_id = chat_session_id;
      this.currentReceiveSessionInfo.isNewAnswer = this.isNewChat;
      this.messageList.push(botAnswer);
      this.scrollToEnd();
      this.inputStatus = 'outputting';

      const currentSessionId = this.sessionId;

      try {
      	const actionParams = {
      		chat_session_id,
	      	id: questionId,
	      	action_name: actionName,
	      	action_id: actionId,
	      	bot_type: this.chatSessionUseModel,
      	};
      	const { task_id, answer_id } = await trrigerImageAction(actionParams);

	      // 会话发生变化
	    	if (currentSessionId !== this.sessionId) {
	    		return;
	    	}

	    	if (this.currentImageTaskInfo.status === 3) {
	    		return;
	    	}

	    	this.scrollToEnd();

      	// 保存当前正在绘制的图片任务id
      	this.currentImageTaskInfo.status = 1;
	      this.currentImageTaskInfo.task_id = task_id;
	      this.currentImageTaskInfo.answer_id = answer_id;
	      this.vueBotAnswer.id = answer_id;

	      await sleep(500);
	      botAnswer.percent = 5;

	      // 轮训检测任务执行状态
      	const taskStatusInfo = await this.checkImageTaskStatus(task_id);

      	this.resetCurrentImageTaskInfo();

      	// 任务执行成功
      	if (taskStatusInfo.status === 2) {
      		this.currentImageTaskInfo.status = 2;
      		botAnswer.percent = 100;

      		await sleep(500);

      		// 绘制完成
      		const { content, content_type } = taskStatusInfo.answer_detail;
      		const contentResult = this.makeAnswerContent(content, content_type);

      		this.vueBotAnswer.contentType = content_type;
      		this.vueBotAnswer.content = contentResult;
      		this.imageDrawDone(this.currentReceiveSessionInfo);
      	}

      	// 任务执行失败
      	if (taskStatusInfo.status === 3) {
      		this.currentImageTaskInfo.status = 2;
      		this.vueBotAnswer.errorMsg = taskStatusInfo.remark;
      		this.imageDrawDone(this.currentReceiveSessionInfo);
      	}

      	// 任务被取消，把占位answer删除掉
      	if (taskStatusInfo.status === 4) {
      		this.currentImageTaskInfo.status = 2;
      	}

      	this.scrollToEnd();
      }
      catch(err) {
      	if (err && err.code === 311) {
      		this.vueBotAnswer.errorMsg = '余额不足, 请购买积分';
      	}

      	if (err && err.code === 456) {
      		this.vueBotAnswer.errorMsg = err.message;
      	}

      	this.resetCurrentImageTaskInfo();
      	this.imageDrawDone(this.currentReceiveSessionInfo);
      	this.scrollToEnd();
      }
		},
	}
}