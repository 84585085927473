import { network } from './network';

export async function getCouponsBuyPage(opts) {
	const res = await network({
		url: '/api/query_coupon',
		method: 'get',
		params: {
			page: opts.pageNumber,
      page_size: opts.page_size
		}
	});

	return res;
}

export async function getAllCoupons(opts) {
	const res = await network({
		url: '/api/query_coupon_all',
		method: 'get',
		params: {}
	});

	if (res.code !== 0) {
		return [];
	}

	return res.data.list;
}

export async function getAllSysCoupons(opts) {
	const res = await network({
		url: '/api/query_canget_sysCoupons',
		method: 'get',
		params: {}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res.data.list;
}

export async function getCoupon(params) {
	const { couponId } = params;
	const res = await network({
		url: '/api/get_coupon',
		method: 'post',
		params: {
			coupon_id: couponId,
		}
	});

	if (res.code !== 0) {
		return Promise.reject(res);
	}

	return res;
}