export const regenerateMixin = {
	methods: {
		regenerate(opts) {
			const { messageId } = opts;

			// 先把后面的消息全部清空
			this.deleteAfterMessage(messageId);

			const questionId = this.getRegenerateQuestionId();

			this.answerFail = false;

			// 绘图模型
    	if (this.chatSessionUseModel === 2 || this.chatSessionUseModel === 3) {
    		this.receiveImageAnswer({
    			questionId: questionId,
    			chat_session_id: this.chatSessionId,
    			regenerte: true
    		});
    		return;
    	}
    	
			this.receiveAnswer(questionId, true, this.chatSessionId);
		},

		inputRegenerate() {
    	const messageId = this.messageList[this.messageList.length - 1].id;

    	this.regenerate({
    		messageId
    	});
    },

		getRegenerateQuestionId() {
			return this.messageList[this.messageList.length - 1].id;
		},

		deleteAfterMessage(messageId) {
			for (let i = 0; i < this.messageList.length; i++) {
				if (this.messageList[i].id !== messageId) {
					continue;
				}

				this.messageList.splice(i, this.messageList.length - i);
				break;
			}
		}
	}
};