import { addAPIKey, addVIP, queryKeys, refreshKeys, activeKey } from '@/api/admin';

export default {
  name: 'page-admin',

  data() {
    return {
      apiKeyForm: {
        apiKey: '',
        type: 'openai',
        business_username: ''
      },

      vipUser: {
        username: '',
        password: ''
      },

      rules: {
        apiKey: [
          { required: true, message: '请输入api_key', trigger: 'blur' },
        ],
        business_username: [
          { required: true, message: '请输入账号名称', trigger: 'blur' },
        ],
      },

      vipUserRule: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },

      keys: [],
      keyRefreshing: false
    }
  },

  methods: {
    init() {
      this.createKeyList();
    },

    async createKeyList() {
      const list = await queryKeys();

      this.keys = list.map((keyInfo) => {
        return {
          ...keyInfo,
          activeLoading: false
        };
      });
    },

    addOpenKey() {
      this.$refs.openkeyForm.validate((valid) => {
        if (valid) {
          this.sendAddkeyRequest();
        }
      });
    },

    async addVIPUser() {
      this.$refs.vipUserForm.validate((valid) => {
        if (valid) {
          this.sendAddVIPUserRequest();
        }
      });
    },

    async sendAddVIPUserRequest() {
      try {
        const res = await addVIP({
          username: this.vipUser.username,
          password: this.vipUser.password
        });

        if (res.code !== 0) {
          this.$message({
            message: res.message,
            type: 'error',
          });
          return;
        }

        this.$message({
          message: '添加成功',
          type: 'success',
        });
        this.vipUser.username = '';
        this.vipUser.password = '';
      } catch(err) {
        this.$message({
          message: err.message,
          type: 'error'
        });
      }
    },

    async refreshKeyStatus() {
      this.keyRefreshing = true;
      
      const res = await refreshKeys();

      this.keyRefreshing = false;
      if (res.code === 0) {
        this.$message({
          message: '刷新成功',
          type: 'success'
        });
      }
    },

    async sendAddkeyRequest() {
      try {
        const res = await addAPIKey({
          api_key: this.apiKeyForm.apiKey,
          type: this.apiKeyForm.type,
          business_username: this.apiKeyForm.business_username
        });

        console.log({
          api_key: this.apiKeyForm.apiKey,
          type: this.apiKeyForm.type,
          business_username: this.apiKeyForm.business_username
        });

        if (res.code !== 0) {
          this.$message({
            message: res.message,
            type: 'error'
          });
          return;
        }

        this.$message({
          message: '新增成功',
          type: 'success'
        });
        this.apiKeyForm.apiKey = '';
      } catch(err) {
        this.$message({
          message: err.message,
          type: 'error'
        });
      }
    },

    async activeGroup(row) {
      row.activeLoading = true;
      try {
        const res = await activeKey({
          group_id: row.group_id
        });

        if (res.code === 0) {
          this.$message({
            message: '激活成功',
            type: 'success'
          });
        }
        else {
          this.$message({
            message: res.message,
            type: 'error'
          });
        }
      }
      catch(err) {
        this.$message({
          message: '激活失败',
          type: 'error'
        });
      }
      row.activeLoading = false;
    }
  },

  mounted() {
    this.init();
  }
}